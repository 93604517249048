import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const BgSlide7 = () => (
  <StaticImage
    style={{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    }}
    layout="fullWidth"
    alt=""
    placeholder="blurred"
    src="../../images/heat_bg_slide7.jpg"
    formats={['auto']}
  />
);

export default BgSlide7;
