import * as React from 'react';
import { motion } from 'framer-motion';

function HeatAnimationSlide7(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <filter id="heat-shadow-slide-7" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="10" floodOpacity="0.4" />
        </filter>
        <filter id="heat-shadow-slide-7-1" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="5" floodOpacity="0.4" />
        </filter>
        <filter id="heat-shadow-slide-7-inner" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">

          <feGaussianBlur in="SourceAlpha" stdDeviation="3" result="blur" />
          <feOffset dy="2" dx="3" />
          <feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff" />

          <feFlood floodColor="#444444" floodOpacity="0.4" />
          <feComposite in2="shadowDiff" operator="in" />
          <feComposite in2="SourceGraphic" operator="over" result="firstfilter" />
          <feGaussianBlur in="firstfilter" stdDeviation="10" result="blur2" />
          <feOffset dy="-2" dx="-3" />
          <feComposite in2="firstfilter" operator="arithmetic" k2="-1" k3="1" result="shadowDiff" />

          <feFlood floodColor="#444444" floodOpacity="0.4" />
          <feComposite in2="shadowDiff" operator="in" />
          <feComposite in2="firstfilter" operator="over" />
        </filter>
        <clipPath id="heat_slide_7__clip-path">
          <path
            id="heat_slide_7__SKY"
            className="heat_slide_7__cls-1"
            d="M-.55 0h1441.1v797H-.55z"
          />
        </clipPath>
        <clipPath id="heat_slide_7__clip-path-2">
          <path
            className="heat_slide_7__cls-1"
            d="M195.11 724.11h77.5V477.6l-30.01-60.31h-47.49v306.82z"
          />
        </clipPath>
        <clipPath id="heat_slide_7__clip-path-3">
          <path
            className="heat_slide_7__cls-2"
            d="M195.11 724.11h77.5V477.6l-30.01-60.31h-47.49v306.82z"
          />
        </clipPath>
        <clipPath id="heat_slide_7__clip-path-4">
          <path
            className="heat_slide_7__cls-2"
            d="M190.27 438.64h108.07V686.6H190.27z"
          />
        </clipPath>
        <clipPath id="heat_slide_7__clip-path-6">
          <path
            className="heat_slide_7__cls-1"
            d="M503.94 271.67l33.73-8.89a14.63 14.63 0 0010.41-17.86v-.07a14.62 14.62 0 00-17.85-10.44l-33.73 8.9a14.64 14.64 0 00-10.42 17.86v.07a14.61 14.61 0 0017.86 10.43z"
          />
        </clipPath>
        <clipPath id="heat_slide_7__clip-path-7">
          <path
            className="heat_slide_7__cls-1"
            d="M528.19 243.84a7.71 7.71 0 115.79 9.37 7.77 7.77 0 01-5.79-9.37"
          />
        </clipPath>
        <clipPath id="heat_slide_7__clip-path-8">
          <path
            className="heat_slide_7__cls-1"
            d="M1072.11 368.55a9 9 0 11-9.95-8.22 9.14 9.14 0 019.95 8.22"
          />
        </clipPath>
        <clipPath id="heat_slide_7__clip-path-9">
          <rect
            className="heat_slide_7__cls-1"
            x={654.57}
            y={428.08}
            width={156.04}
            height={43.07}
            rx={21.45}
          />
        </clipPath>
        <linearGradient
          id="heat_slide_7__linear-gradient"
          x1={641.38}
          y1={623.05}
          x2={1528.62}
          y2={623.05}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#59b763" />
          <stop offset={1} stopColor="#59b763" />
        </linearGradient>
        <linearGradient
          id="heat_slide_7__linear-gradient-2"
          x1={3951.25}
          y1={2383.21}
          x2={3984.52}
          y2={2383.21}
          gradientTransform="matrix(.81 -.97 .92 .65 -4212.14 2786.78)"
          xlinkHref="#heat_slide_7__linear-gradient"
        />
        <linearGradient
          id="heat_slide_7__linear-gradient-3"
          x1={-102.23}
          y1={669.06}
          x2={615.65}
          y2={669.06}
          xlinkHref="#heat_slide_7__linear-gradient"
        />
        <linearGradient
          id="heat_slide_7__linear-gradient-4"
          x1={922.59}
          y1={553.64}
          x2={955.79}
          y2={553.64}
          gradientTransform="rotate(-9.58 431.166 4644.645)"
          xlinkHref="#heat_slide_7__linear-gradient"
        />
        <mask
          id="heat_slide_7__mask"
          x={856}
          y={630}
          width={623}
          height={189}
          maskUnits="userSpaceOnUse"
        />
        <mask
          id="heat_slide_7__mask-2"
          x={870}
          y={648}
          width={624}
          height={190}
          maskUnits="userSpaceOnUse"
        />
        <mask
          id="heat_slide_7__mask-3"
          x={-74}
          y={655}
          width={416}
          height={209}
          maskUnits="userSpaceOnUse"
        />
        <mask
          id="heat_slide_7__mask-4"
          x={-213}
          y={669}
          width={515}
          height={209}
          maskUnits="userSpaceOnUse"
        />
        <filter
          id="heat_slide_7__luminosity-invert"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feColorMatrix values="-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0" />
        </filter>
        <style>
          {
            '.heat_slide_7__cls-1{fill:none}.heat_slide_7__cls-2{fill:#308462}.heat_slide_7__cls-5{opacity:.4}.heat_slide_7__cls-13,.heat_slide_7__cls-21,.heat_slide_7__cls-5{mix-blend-mode:multiply}.heat_slide_7__cls-10{fill:#3a9354}.heat_slide_7__cls-11{fill:#72cbf1}.heat_slide_7__cls-13{opacity:.15}.heat_slide_7__cls-19{fill:#297c59}.heat_slide_7__cls-21{opacity:.3}.heat_slide_7__cls-25{fill:#eb826a}.heat_slide_7__cls-28{fill:#414042}.heat_slide_7__cls-30{fill:#f1f2f2}.heat_slide_7__cls-31{fill:#f99c43}.heat_slide_7__cls-33,.heat_slide_7__cls-38{fill:#6effff}.heat_slide_7__cls-33{stroke:#22e4f2;stroke-miterlimit:10;stroke-width:2px}.heat_slide_7__cls-34{fill:#fced6f}.heat_slide_7__cls-36{fill:#f4cb5d}.heat_slide_7__cls-39{clip-path:url(#heat_slide_7__clip-path-9)}.heat_slide_7__cls-40{fill:#e06371}.heat_slide_7__cls-41{fill:#ee8c74}.heat_slide_7__cls-45{fill:#f2ffd8}.heat_slide_7__cls-46{filter:url(#heat_slide_7__luminosity-invert)}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g
          clipPath="url(#heat_slide_7__clip-path)"
          id="heat_slide_7__Layer_1"
          data-name="Layer 1"
        >
          <g id="heat_slide_7__FLOAT_OBJECTS-2" data-name="FLOAT OBJECTS">
            <g
              id="heat_slide_7__bee"
              // filter="url(#heat-shadow-slide-7-1)"
              transform="translate(-1000, -150)"
            >
              <animateMotion
                dur="13s"
                repeatCount="indefinite"
                keyPoints="1;0"
                keyTimes="0;1"
                calcMode="linear"
                path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
              />
              <path
                className="heat_slide_7__cls-25"
                d="M527.92 273.01l2.68-.66.02-7.85-2.68.66-.02 7.85zM522.83 274.26l2.68-.66.02-7.85-2.68.66-.02 7.85zM511.04 277.16l2.68-.66.02-7.85-2.68.66-.02 7.85zM505.95 278.41l2.68-.66.02-7.84-2.68.66-.02 7.84z"
              />
              <path
                d="M503.94 271.67l33.73-8.89a14.63 14.63 0 0010.41-17.86v-.07a14.62 14.62 0 00-17.85-10.44l-33.73 8.9a14.64 14.64 0 00-10.42 17.86v.07a14.61 14.61 0 0017.86 10.43z"
                fill="#fbec6d"
              />
              <g clipPath="url(#heat_slide_7__clip-path-6)">
                <path
                  className="heat_slide_7__cls-28"
                  d="M558.53 255.16a12.51 12.51 0 01-21.11 0L536 256a14.13 14.13 0 0023.86 0z"
                />
              </g>
              <g clipPath="url(#heat_slide_7__clip-path-7)">
                <path
                  className="heat_slide_7__cls-30"
                  d="M528.19 243.84a7.71 7.71 0 115.79 9.37 7.77 7.77 0 01-5.79-9.37"
                />
                <path
                  className="heat_slide_7__cls-28"
                  d="M535.71 245.56a7.72 7.72 0 115.79 9.37 7.77 7.77 0 01-5.79-9.37"
                />
              </g>
              <path
                className="heat_slide_7__cls-31"
                d="M507.51 270.73l20.57-5.42-7.44-28.37-20.57 5.42 7.44 28.37z"
              />
              <motion.path
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{
                  duration: 1, repeat: Infinity,
                }}
                style={{
                  transformBox: 'fill-box',
                  originY: 'bottom',
                  originX: 'center',
                }}
                className="heat_slide_7__cls-25"
                d="M469.56 219.16l2.57-.68A30.11 30.11 0 01508.92 240l-2.57.68a30.11 30.11 0 01-36.79-21.5z"
              />
              <motion.path
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{
                  duration: 1, repeat: Infinity,
                }}
                style={{
                  transformBox: 'fill-box',
                  originY: 'bottom',
                  originX: 'center',
                }}
                d="M526 201.52l.7 2.67A30.15 30.15 0 01505.26 241h-.05l-.7-2.68A30.15 30.15 0 01526 201.53z"
                fill="#f5b1a6"
              />
            </g>
            <g id="heat_slide_7__water_drops" data-name="water drops">
              <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 3, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={324.11}
                cy={391.7}
                rx={6.78}
                ry={6.8}
              />
              {/* <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={472.78}
                cy={368.06}
                rx={6.78}
                ry={6.8}
              /> */}
              <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 5, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={1216.51}
                cy={339.88}
                rx={6.78}
                ry={6.8}
              />
              {/* <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 3, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={1178.93}
                cy={406.23}
                rx={6.78}
                ry={6.8}
              /> */}
              <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 4, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={1014.27}
                cy={311.19}
                rx={6.78}
                ry={6.8}
              />
              {/* <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 5, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={851.41}
                cy={615.55}
                rx={6.78}
                ry={6.8}
              /> */}
              {/* <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 6, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={823.96}
                cy={349.99}
                rx={6.78}
                ry={6.8}
              /> */}
              {/* <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 7, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={780.39}
                cy={318.49}
                rx={6.78}
                ry={6.8}
              /> */}
              <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 12, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={675.64}
                cy={383.94}
                rx={6.78}
                ry={6.8}
              />

              <motion.g
                // filter="url(#heat-shadow-slide-7-1)"
                id="heat_slide_7__Bird"
                animate={{
                  rotate: ['-3deg', '3deg', '-3deg', '3deg', '-3deg'],
                  x: [-40, 40, -40],
                }}
                transition={{
                  duration: 6, repeat: Infinity,
                }}
              >
                <g id="heat_slide_7__bird_A" data-name="bird A">
                  <path
                    className="heat_slide_7__cls-38"
                    d="M783.24 364.56l-.37-.64a2.31 2.31 0 00-1.43-1.11 2.34 2.34 0 00-1.79.25L718 400a2.35 2.35 0 00-.83 3.16l22.28 39.33a2.34 2.34 0 001.43 1.11 2.46 2.46 0 00.6.07 2.3 2.3 0 001.19-.33l24-14.36c21.84-13.11 29.27-41.98 16.57-64.42zM783.88 357.55l-35 9.26a1 1 0 01-1.27-.77l-11.29-45.13a1.07 1.07 0 01.75-1.3l19.27-5.09c16.14-4.27 32.58 5.71 36.73 22.29l.84 3.36a14.31 14.31 0 01-10.03 17.38z"
                  />
                  <path
                    className="heat_slide_7__cls-38"
                    d="M797.87 345.21l-14 3.7c-21 5.56-42.43-7.44-47.83-29l23.92-6.33c18.28-4.83 36.9 6.48 41.61 25.26a5.28 5.28 0 01-3.7 6.37zM753.22 388.54l-51 13.48L690 353c-3-11.84 3.72-23.84 14.94-26.81l30.64-8.1z"
                  />
                  <path
                    className="heat_slide_7__cls-38"
                    d="M752.93 387.38l-35.6 9.41c-20.86 5.52-33.42 27.37-28.06 48.8l1.59 6.34a7.27 7.27 0 008.87 5.39l34.56-9.13c17.54-4.64 28.1-23 23.59-41z"
                  />
                  <path
                    className="heat_slide_7__cls-38"
                    d="M743.85 402.91l-4.14-13.19a2.18 2.18 0 00-2.71-1.42l-32.67 10.31a40.2 40.2 0 00-26.2 50.39l2 6.21a2.18 2.18 0 002.07 1.53 2.06 2.06 0 00.65-.11l39.64-12.5a32.88 32.88 0 0021.41-41.23z"
                  />
                  <g id="heat_slide_7__glasses">
                    <path
                      className="heat_slide_7__cls-38"
                      d="M733.55 314.87l12.81-3.64 1.49 5.3-12.8 3.64-1.5-5.3z"
                    />
                    <path
                      className="heat_slide_7__cls-38"
                      d="M735.25 312.62l-36.86 10.47a1.71 1.71 0 00-1.17 2.1l2.36 8.34a20.85 20.85 0 1040.11-11.4l-2.35-8.34a1.69 1.69 0 00-2.09-1.17z"
                    />
                    <path
                      className="heat_slide_7__cls-38"
                      d="M704.9 327.75l1.57 5.57c3.53 12.47 15 9.23 15 9.23"
                    />
                  </g>
                </g>
                <path
                  className="heat_slide_7__cls-38"
                  d="M802.83 449.46l-19.89-11.52 20.59-35.78 19.89 11.52-20.59 35.78zM672.31 445.13a11.45 11.45 0 01-16.22 0l-12.9-12.93a11.56 11.56 0 010-16.3 11.43 11.43 0 0116.22 0l12.9 12.93a11.55 11.55 0 010 16.3z"
                />
                <path
                  className="heat_slide_7__cls-38"
                  d="M833.5 442.55l-31.3-18.12a10 10 0 01-3.64-13.63 9.91 9.91 0 0113.57-3.64l31.29 18.12a10 10 0 013.64 13.63 9.92 9.92 0 01-13.56 3.64z"
                />
                <rect
                  className="heat_slide_7__cls-38"
                  x={797.62}
                  y={376.86}
                  width={19.81}
                  height={40.84}
                  rx={8.51}
                  transform="translate(-6.88 14.3)"
                />
                <path
                  className="heat_slide_7__cls-38"
                  d="M833.49 399.94l-16.75 16.8a8.47 8.47 0 01-12 0l-2-2a8.55 8.55 0 010-12.08l16.75-16.81a8.49 8.49 0 0112 0l2 2a8.55 8.55 0 010 12.09z"
                />
                <g className="heat_slide_7__cls-39">
                  <rect
                    className="heat_slide_7__cls-38"
                    x={654.57}
                    y={428.08}
                    width={156.04}
                    height={43.07}
                    rx={21.45}
                  />
                  <ellipse
                    className="heat_slide_7__cls-38"
                    cx={673.04}
                    cy={436.07}
                    rx={15.43}
                    ry={15.47}
                  />
                  <ellipse
                    className="heat_slide_7__cls-38"
                    cx={708.33}
                    cy={468.39}
                    rx={15.43}
                    ry={15.47}
                  />
                  <ellipse
                    className="heat_slide_7__cls-38"
                    cx={736.76}
                    cy={433.14}
                    rx={15.43}
                    ry={15.47}
                  />
                  <ellipse
                    className="heat_slide_7__cls-38"
                    cx={793.15}
                    cy={427.69}
                    rx={15.43}
                    ry={15.47}
                  />
                  <ellipse
                    className="heat_slide_7__cls-38"
                    cx={777.1}
                    cy={467.7}
                    rx={15.43}
                    ry={15.47}
                  />
                </g>
                <motion.ellipse
                  animate={{
                    y: [0, -300],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 6, delay: 15, repeat: Infinity,
                  }}
                  className="heat_slide_7__cls-38"
                  cx={591.87}
                  cy={359.74}
                  rx={6.78}
                  ry={6.8}
                />
                <g>
                  <g id="heat_slide_7__bird_A-2" data-name="bird A">
                    <path
                      className="heat_slide_7__cls-40"
                      d="M783.24 364.56l-.37-.64a2.31 2.31 0 00-1.43-1.11 2.34 2.34 0 00-1.79.25L718 400a2.35 2.35 0 00-.83 3.16l22.28 39.33a2.34 2.34 0 001.43 1.11 2.46 2.46 0 00.6.07 2.3 2.3 0 001.19-.33l24-14.36c21.84-13.11 29.27-41.98 16.57-64.42z"
                    />
                    <path
                      className="heat_slide_7__cls-36"
                      d="M783.88 357.55l-35 9.26a1 1 0 01-1.27-.77l-11.29-45.13a1.07 1.07 0 01.75-1.3l19.27-5.09c16.14-4.27 32.58 5.71 36.73 22.29l.84 3.36a14.31 14.31 0 01-10.03 17.38z"
                    />
                    <path
                      className="heat_slide_7__cls-34"
                      d="M797.87 345.21l-14 3.7c-21 5.56-42.43-7.44-47.83-29l23.92-6.33c18.28-4.83 36.9 6.48 41.61 25.26a5.28 5.28 0 01-3.7 6.37z"
                    />
                    <path
                      className="heat_slide_7__cls-41"
                      d="M753.22 388.54l-51 13.48L690 353c-3-11.84 3.72-23.84 14.94-26.81l30.64-8.1z"
                    />
                    <path
                      className="heat_slide_7__cls-41"
                      d="M752.93 387.38l-35.6 9.41c-20.86 5.52-33.42 27.37-28.06 48.8l1.59 6.34a7.27 7.27 0 008.87 5.39l34.56-9.13c17.54-4.64 28.1-23 23.59-41z"
                    />
                    <path
                      className="heat_slide_7__cls-40"
                      d="M743.85 402.91l-4.14-13.19a2.18 2.18 0 00-2.71-1.42l-32.67 10.31a40.2 40.2 0 00-26.2 50.39l2 6.21a2.18 2.18 0 002.07 1.53 2.06 2.06 0 00.65-.11l39.64-12.5a32.88 32.88 0 0021.41-41.23z"
                    />
                    <g id="heat_slide_7__glasses-2" data-name="glasses">
                      <path
                        fill="#796598"
                        d="M733.55 314.87l12.81-3.64 1.49 5.3-12.8 3.64-1.5-5.3z"
                      />
                      <path
                        d="M735.25 312.62l-36.86 10.47a1.71 1.71 0 00-1.17 2.1l2.36 8.34a20.85 20.85 0 1040.11-11.4l-2.35-8.34a1.69 1.69 0 00-2.09-1.17z"
                        fill="#433845"
                      />
                      <path
                        d="M704.9 327.75l1.57 5.57c3.53 12.47 15 9.23 15 9.23"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={4.23}
                        opacity={0.5}
                        fill="none"
                      />
                    </g>
                  </g>
                  <path
                    className="heat_slide_7__cls-34"
                    d="M802.83 449.46l-19.89-11.52 20.59-35.78 19.89 11.52-20.59 35.78zM672.31 445.13a11.45 11.45 0 01-16.22 0l-12.9-12.93a11.56 11.56 0 010-16.3 11.43 11.43 0 0116.22 0l12.9 12.93a11.55 11.55 0 010 16.3z"
                  />
                  <path
                    className="heat_slide_7__cls-34"
                    d="M833.5 442.55l-31.3-18.12a10 10 0 01-3.64-13.63 9.91 9.91 0 0113.57-3.64l31.29 18.12a10 10 0 013.64 13.63 9.92 9.92 0 01-13.56 3.64z"
                  />
                  <rect
                    className="heat_slide_7__cls-34"
                    x={797.62}
                    y={376.86}
                    width={19.81}
                    height={40.84}
                    rx={8.51}
                    transform="translate(-6.88 14.3)"
                  />
                  <path
                    className="heat_slide_7__cls-34"
                    d="M833.49 399.94l-16.75 16.8a8.47 8.47 0 01-12 0l-2-2a8.55 8.55 0 010-12.08l16.75-16.81a8.49 8.49 0 0112 0l2 2a8.55 8.55 0 010 12.09z"
                  />
                  <g className="heat_slide_7__cls-39">
                    <rect
                      className="heat_slide_7__cls-34"
                      x={654.57}
                      y={428.08}
                      width={156.04}
                      height={43.07}
                      rx={21.45}
                    />
                    <ellipse
                      className="heat_slide_7__cls-45"
                      cx={673.04}
                      cy={436.07}
                      rx={15.43}
                      ry={15.47}
                    />
                    <ellipse
                      className="heat_slide_7__cls-45"
                      cx={708.33}
                      cy={468.39}
                      rx={15.43}
                      ry={15.47}
                    />
                    <ellipse
                      className="heat_slide_7__cls-45"
                      cx={736.76}
                      cy={433.14}
                      rx={15.43}
                      ry={15.47}
                    />
                    <ellipse
                      className="heat_slide_7__cls-45"
                      cx={793.15}
                      cy={427.69}
                      rx={15.43}
                      ry={15.47}
                    />
                    <ellipse
                      className="heat_slide_7__cls-45"
                      cx={777.1}
                      cy={467.7}
                      rx={15.43}
                      ry={15.47}
                    />
                  </g>

                </g>
              </motion.g>

              <g
                // filter="url(#heat-shadow-slide-7-1)"
                className="duck"
                transform="translate(-1100, -460)"
              >
                <animateMotion
                  dur="100s"
                  repeatCount="indefinite"
                  path="M1552.48,374.09c-405.19,63.74-518.83,90.47-594,137.13-8.59,5.33-98.32,49.76-136.62,60.07-82.63,22.23-160-6.47-278-66.27-41.76-21.17-102.22-63.5-190.73-64.66C253,439.05,210.91,459.76,115.94,459.7c-70.1,0-116.49-75.91-294.56-85.61"
                />
                <path
                  className="heat_slide_7__cls-31"
                  d="M1064.88 353.79a15.19 15.19 0 0115.21 15.21v47.95h-30.39V369a15.19 15.19 0 0115.19-15.19z"
                />
                <path
                  className="heat_slide_7__cls-34"
                  d="M1049.69 416.92h82.7v.08a33.84 33.84 0 01-33.84 33.84h-37.95a10.91 10.91 0 01-10.91-10.84v-23.08z"
                />
                <path
                  d="M1098.25 416.92h34.14v.08a33.84 33.84 0 01-33.84 33.84h-34.14v-.13a33.84 33.84 0 0133.84-33.79z"
                  fill="#f7dd41"
                />
                <path
                  className="heat_slide_7__cls-36"
                  d="M1049.7 401.78h30.39v15.13h-30.39z"
                />
                <g clipPath="url(#heat_slide_7__clip-path-8)">
                  <path
                    className="heat_slide_7__cls-30"
                    d="M1072.11 368.55a9 9 0 11-9.95-8.22 9.14 9.14 0 019.95 8.22"
                  />
                  <path
                    className="heat_slide_7__cls-28"
                    d="M1063.11 369.49a9 9 0 11-9.94-8.22 9.13 9.13 0 019.94 8.22"
                  />
                </g>
                <path
                  className="heat_slide_7__cls-36"
                  d="M1035 370.43h14.66v5.62H1035a2.8 2.8 0 01-2.8-2.8 2.8 2.8 0 012.8-2.8z"
                />
                <path
                  className="heat_slide_7__cls-34"
                  d="M1035 370.43h14.66a5.6 5.6 0 01-5.6 5.6H1035a2.8 2.8 0 01-2.8-2.8 2.8 2.8 0 012.8-2.8z"
                />
              </g>
              <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 20, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={571.04}
                cy={521.2}
                rx={6.78}
                ry={6.8}
              />
              {/* <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 10, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={295.45}
                cy={471.14}
                rx={6.78}
                ry={6.8}
              /> */}
              <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 11, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={144.65}
                cy={455.5}
                rx={6.78}
                ry={6.8}
              />
              {/* <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 12, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={387.76}
                cy={497.74}
                rx={6.78}
                ry={6.8}
              /> */}
              <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 13, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={705.03}
                cy={582.71}
                rx={6.78}
                ry={6.8}
              />
              {/* <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 14, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={969.05}
                cy={550.03}
                rx={6.78}
                ry={6.8}
              /> */}
              <motion.ellipse
                animate={{
                  y: [0, -300],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 6, delay: 15, repeat: Infinity,
                }}
                className="heat_slide_7__cls-33"
                cx={1000.3}
                cy={490.16}
                rx={6.78}
                ry={6.8}
              />
            </g>
          </g>
          <g id="heat_slide_7__bottom">
            <g
              id="heat_slide_7__float_objects"
              data-name="float objects"
              filter="url(#heat-shadow-slide-7)"
            >
              {/* add_shadow */}

              <path
                className="heat_slide_7__cls-2"
                d="M1480.78 868.6l18.68-143.91s-192.36-6.16-252.5 16.42-206 134.77-206 134.77z"
              />
            </g>
            {/* add_shadow */}

            <path
              d="M1524.62 816.68l-883.24 11.1s217-195.08 309.23-228.61 164.71-54.36 216.75-99.06 361.26-81.79 361.26-81.79z"
              fill="url(#heat_slide_7__linear-gradient)"
            />
            <path
              d="M1161.39 478.22a2.55 2.55 0 012.89-.85.51.51 0 01.15.07l11.59 7.68-5.72-24a2.46 2.46 0 011.23-2.56 2.35 2.35 0 012.78.11l.1.09 14.38 17.8 1.88-14.49a.92.92 0 01.08-.27 2.66 2.66 0 012.27-1.48 2 2 0 012.09 1.28l7.48 19a.84.84 0 01-.34 1l-25 17.43a.84.84 0 01-1.12-.09l-14.82-17.9a2.19 2.19 0 01.08-2.82z"
              fill="url(#heat_slide_7__linear-gradient-2)"
            />
            <g
              id="heat_slide_7__Bottom_Left"
              data-name="Bottom Left"
              filter="url(#heat-shadow-slide-7)"
            >
              <path
                d="M359.86 819.7s273.31-174.89 254.9-208.41-92.53-24.42-154.57-26.85c-59.65-2.34-147.75-41.1-226.32-53.19s-336.1-12.82-336.1-12.82V819.7z"
                fill="url(#heat_slide_7__linear-gradient-3)"
              />
              <path
                d="M239.44 515.07a1.89 1.89 0 012.1 1.17.74.74 0 010 .14l2.36 12.14 9.88-19.52a1.89 1.89 0 012.08-1 1.92 1.92 0 011.52 1.76.2.2 0 010 .11l-1.58 20.63 9-8.6a.64.64 0 01.19-.13 1.87 1.87 0 012.09.37 2 2 0 01.48 2.12l-6.12 17.25a.7.7 0 01-.74.46l-23.7-3.38a.68.68 0 01-.58-.74l1.39-20.94a2 2 0 011.63-1.84z"
                fill="url(#heat_slide_7__linear-gradient-4)"
              />
            </g>
            {/* add_shadow */}
            <g filter="url(#heat-shadow-slide-7)">
              <path
                className="heat_slide_7__cls-10"
                d="M1491.4 552.53c1.8-1.94-339.32 3.57-393 40S968.65 601 922 628.38C849.54 670.93 685.72 811.1 685.72 811.1l772.51-4.73z"
              />
              <path
                className="heat_slide_7__cls-10"
                d="M1104.21 548.48h6.75v71.36h-6.75z"
              />
              <path
                className="heat_slide_7__cls-10"
                d="M1091.42 554.06v-6.31H1087v6.31c0 13.43 11.34 20.21 22.81 20.36v-3.9c-9.27-.15-18.39-5.63-18.39-16.46z"
              />
              <path
                className="heat_slide_7__cls-10"
                d="M1073.4 542.77v-6.31h-4.4v6.31c0 13.42 11.34 20.21 22.81 20.35v-3.89c-9.29-.15-18.41-5.64-18.41-16.46zM1126.17 545.05v-6.74h3.95v6.74c0 14.31-10.11 21.54-20.33 21.7v-4.16c8.21-.15 16.38-6 16.38-17.54z"
              />
              <path
                className="heat_slide_7__cls-10"
                d="M1132.76 554.24h-6.71v4h6.71c14.27 0 21.48-10.14 21.64-20.4h-4.14c-.16 8.24-5.99 16.4-17.5 16.4z"
              />
              <path
                className="heat_slide_7__cls-10"
                d="M1179.36 545.37a3.28 3.28 0 01-3.29 3.27h-146.55a3.28 3.28 0 01-3.29-3.27c0-1.2.07-2.37.16-3.53a3.26 3.26 0 003.13 2.28h146.55a3.26 3.26 0 003.13-2.28c.09 1.16.16 2.33.16 3.53z"
              />
              <path
                className="heat_slide_7__cls-10"
                d="M1179.2 541.84a3.26 3.26 0 01-3.13 2.28h-146.55a3.26 3.26 0 01-3.13-2.28 36.11 36.11 0 0135.87-32.63h5.79a16.14 16.14 0 0115.67-12.45h34a16.1 16.1 0 0115.67 12.45h9.93a36.11 36.11 0 0135.88 32.63zM1256.8 550.44c-.69 0-1.38 0-2 .09a23 23 0 00-20.9-13.5 23 23 0 00-21.25 14.33 22.77 22.77 0 00-10.6-2.6 23 23 0 00-23 23 23 23 0 0023 23 23 23 0 0021.26-14.34 22.76 22.76 0 0010.59 2.6c.69 0 1.38 0 2.05-.09a22.94 22.94 0 0043.85-9.52 23 23 0 00-23-22.97z"
              />
              <g>
                <path
                  className="heat_slide_7__cls-10"
                  d="M893.56 592.65l-2.47-1.71c-.83-.57-1.55-.29-1.61.63l-.19 2.73c-1.45 21.41 12.55 47.42 30.63 59.21l2.17 1.41c.72.47 1.39.26 1.49-.47l.32-2.2c2.76-18.79-10.46-45.89-30.34-59.6z"
                />
                <path
                  className="heat_slide_7__cls-10"
                  d="M935.55 591.62l-1-2.4c-.35-.8-1.06-.86-1.6-.13l-1.63 2.2c-13.29 18-17.19 48.62-8.7 68.32l1 2.4c.34.8 1.06.86 1.6.12l1.62-2.2c13.3-18.02 17.16-48.61 8.71-68.31z"
                />
                <path
                  className="heat_slide_7__cls-10"
                  d="M871.25 612.82l-2.79-.88c-.93-.3-1.47.08-1.21.83L868 615c6.45 18.5 30.21 39.36 53.07 46.6l2.79.88c.93.3 1.47-.07 1.21-.83l-.79-2.25c-6.41-18.47-30.18-39.34-53.03-46.58z"
                />
              </g>
              <g id="heat_slide_7__brigde">
                <path
                  id="heat_slide_7__brihge"
                  className="heat_slide_7__cls-10"
                  d="M485.51 666.94s104.92 7.13 152.64 19.79 143.73 58.83 143.73 58.83l51-39.31s-104.81-48.6-159.7-63.2-134.78-15.16-134.78-15.16z"
                />
                <g id="heat_slide_7__legs">
                  <rect
                    className="heat_slide_7__cls-10"
                    x={682.02}
                    y={685.53}
                    width={7.4}
                    height={45.13}
                    rx={3.7}
                  />
                  <rect
                    className="heat_slide_7__cls-10"
                    x={651.82}
                    y={673.52}
                    width={7.4}
                    height={45.13}
                    rx={3.7}
                  />
                  <rect
                    className="heat_slide_7__cls-10"
                    x={615.65}
                    y={658.61}
                    width={7.4}
                    height={45.13}
                    rx={3.7}
                  />
                </g>
              </g>
              <g
                id="heat_slide_7__Bottom_Left-2"
                data-name="Bottom Left"
              >
                <path
                  className="heat_slide_7__cls-10"
                  d="M315.35 811.1S590.25 647 568 617.76c-13.67-17.94-85.85-25.15-149.58-27.43-61.28-2.2-151.8-38.57-232.52-49.91s-345.3-12-345.3-12V811.1z"
                />
                <path
                  className="heat_slide_7__cls-10"
                  d="M65.28 456.91h7.58v80.1h-7.58z"
                />
                <path
                  className="heat_slide_7__cls-10"
                  d="M50.92 463.18v-7.09h-5v7.09c0 15.06 12.73 22.68 25.6 22.84v-4.37c-10.36-.17-20.6-6.32-20.6-18.47zM89.92 453.06v-7.56h4.45v7.56c0 16.06-11.35 24.18-22.83 24.36v-4.67c9.26-.17 18.38-6.75 18.38-19.69z"
                />
                <path
                  className="heat_slide_7__cls-10"
                  d="M121.83 448.73v5.07a5.88 5.88 0 01-5.89 5.9H19a5.88 5.88 0 01-5.89-5.9V450v-1a5.88 5.88 0 005.89 5.63h96.91a5.88 5.88 0 005.92-5.9z"
                />
                <path
                  className="heat_slide_7__cls-10"
                  d="M121.83 445.84v2.89a5.88 5.88 0 01-5.89 5.9H19a5.88 5.88 0 01-5.86-5.55A40.92 40.92 0 0140 411.54a40.77 40.77 0 0131.77-15.22 36.66 36.66 0 0131.06 17.29 36.85 36.85 0 0119 32.23z"
                />
                <path
                  className="heat_slide_7__cls-10"
                  d="M121.83 445.84v2.89a5.88 5.88 0 01-5.89 5.9H19a5.88 5.88 0 01-5.86-5.55A40.92 40.92 0 0140 411.54a40.77 40.77 0 0131.77-15.22 36.66 36.66 0 0131.06 17.29 36.85 36.85 0 0119 32.23zM376.51 561.62c-.69 0-1.37 0-2.05.1a22.93 22.93 0 00-20.89-13.51 23 23 0 00-21.26 14.34 22.76 22.76 0 00-10.59-2.61 23 23 0 00-23 23 23 23 0 0023 23 23 23 0 0021.28-14.3 22.77 22.77 0 0010.6 2.6c.69 0 1.37 0 2.05-.09a22.94 22.94 0 0043.84-9.51 23 23 0 00-22.98-23.02z"
                />
              </g>
            </g>
            <g filter="url(#heat-shadow-slide-7-inner)">
              <path
                className="heat_slide_7__cls-11"
                d="M1476.58 632s-239 13.38-308.63 37.15-309.79 144.91-309.79 144.91l618.42 2.62z"
              />
              <g mask="url(#heat_slide_7__mask)">
                <path d="M1476.58 632s-239 13.38-308.63 37.15-309.79 144.91-309.79 144.91l618.42 2.62z" />
              </g>
            </g>
            <g filter="url(#heat-shadow-slide-7-inner)">
              <path
                d="M1491.4 650.7s-239 13.39-308.63 37.16S873 832.75 873 832.75l618.41 2.62z"
                fill="#6cdff3"
              />
              <g mask="url(#heat_slide_7__mask-2)">
                <path d="M1491.4 650.7s-239 13.39-308.63 37.16S873 832.75 873 832.75l618.41 2.62z" />
              </g>
            </g>
            <g filter="url(#heat-shadow-slide-7)">
              {/* add_shadow */}
              <path
                className="heat_slide_7__cls-2"
                d="M1351.49 500v210H1281V500zm-22 48a2.16 2.16 0 002.19 2.12h3.6a2.16 2.16 0 002.2-2.12v-12.14a2.16 2.16 0 00-2.2-2.13h-3.6a2.16 2.16 0 00-2.19 2.13zM1292 564.64a2.16 2.16 0 002.19 2.12h3.6a2.16 2.16 0 002.2-2.12v-12.16a2.16 2.16 0 00-2.2-2.13h-3.6a2.16 2.16 0 00-2.19 2.13zm0-33a2.16 2.16 0 002.19 2.13h3.6a2.16 2.16 0 002.2-2.13v-12.2a2.16 2.16 0 00-2.2-2.12h-3.6a2.16 2.16 0 00-2.19 2.12z"
              />
              <rect
                className="heat_slide_7__cls-2"
                x={1277.92}
                y={494.98}
                width={76.63}
                height={8.89}
                rx={4.43}
              />
              <rect
                className="heat_slide_7__cls-2"
                x={1301.31}
                y={485.63}
                width={46.54}
                height={12.77}
                rx={4.14}
              />
              <path
                className="heat_slide_7__cls-2"
                d="M1370.51 561.63h6.4v20.4h-6.4z"
              />
              <rect
                className="heat_slide_7__cls-2"
                x={1369.72}
                y={559.51}
                width={7.97}
                height={3.43}
                rx={1.08}
              />
              <path
                className="heat_slide_7__cls-2"
                d="M1368 704.52h50.85a5 5 0 005-5V587.81a5 5 0 00-5-5l-24.28-18.12-26.57 18.08a5 5 0 00-5 5v111.71a5 5 0 005 5.04z"
              />
              <path
                className="heat_slide_7__cls-2"
                d="M1392.09 567.31l30.91 21.27a3.69 3.69 0 005.14-1 3.73 3.73 0 00-.95-5.17l-30.87-21.26a3.7 3.7 0 00-5.15 1 3.72 3.72 0 00.92 5.16z"
              />
              <path
                className="heat_slide_7__cls-2"
                d="M1396 567.31l-30.88 21.27a3.69 3.69 0 01-5.14-1 3.72 3.72 0 011-5.17l30.87-21.26a3.69 3.69 0 015.14 1 3.72 3.72 0 01-.99 5.16z"
              />
              <rect
                className="heat_slide_7__cls-2"
                x={1403.94}
                y={589.65}
                width={7.5}
                height={14.16}
                rx={3.24}
              />
              <rect
                className="heat_slide_7__cls-2"
                x={1376.91}
                y={620.42}
                width={7.5}
                height={14.16}
                rx={3.24}
              />
              <g>
                <path
                  className="heat_slide_7__cls-2"
                  d="M1197.21 595.53V781h60.91V595.53zm20.55 73.79a1.74 1.74 0 01-1.7 1.78h-4a1.73 1.73 0 01-1.69-1.78v-7.85a1.73 1.73 0 011.69-1.77h4a1.74 1.74 0 011.7 1.77zm9.34-26.16v-7.84a1.74 1.74 0 011.69-1.78h4a1.74 1.74 0 011.69 1.78v7.84a1.74 1.74 0 01-1.69 1.78h-4a1.74 1.74 0 01-1.69-1.78zm9.78 104.91a1.73 1.73 0 01-1.69 1.77h-4a1.74 1.74 0 01-1.69-1.77v-7.85a1.73 1.73 0 011.69-1.77h4a1.73 1.73 0 011.69 1.77zm13.79-134.32a1.73 1.73 0 01-1.69 1.77h-4a1.73 1.73 0 01-1.69-1.77v-7.85a1.74 1.74 0 011.69-1.77h4a1.74 1.74 0 011.69 1.77z"
                />
                <rect
                  className="heat_slide_7__cls-2"
                  x={1201.5}
                  y={584.28}
                  width={33.66}
                  height={12.81}
                  rx={4.81}
                />
                <rect
                  className="heat_slide_7__cls-2"
                  x={1194.49}
                  y={592.82}
                  width={66.34}
                  height={8.54}
                  rx={3.99}
                />
                <path
                  className="heat_slide_7__cls-2"
                  d="M1168.27 719.47h80.47v6.44h-80.47z"
                />
                <g>
                  <rect
                    className="heat_slide_7__cls-2"
                    x={1122.52}
                    y={665.03}
                    width={60.91}
                    height={121.75}
                    rx={2.23}
                  />
                  <path
                    className="heat_slide_7__cls-2"
                    d="M1129.12 667.37h47.71a2.24 2.24 0 001.35-4l-23.85-18.27a2.24 2.24 0 00-2.71 0l-23.86 18.27a2.24 2.24 0 001.36 4z"
                  />
                  <path
                    className="heat_slide_7__cls-10"
                    d="M1133.07 674.24a1.85 1.85 0 00-1.85 1.85v91.69a1.85 1.85 0 103.69 0v-91.69a1.85 1.85 0 00-1.84-1.85zM1143.33 674.24a1.85 1.85 0 00-1.84 1.85v91.69a1.85 1.85 0 103.69 0v-91.69a1.85 1.85 0 00-1.85-1.85zM1153.6 674.24a1.85 1.85 0 00-1.84 1.85v91.69a1.84 1.84 0 103.68 0v-91.69a1.84 1.84 0 00-1.84-1.85zM1163.87 674.24a1.84 1.84 0 00-1.84 1.85v91.69a1.84 1.84 0 103.68 0v-91.69a1.84 1.84 0 00-1.84-1.85zM1174.14 674.24a1.84 1.84 0 00-1.84 1.85v91.69a1.84 1.84 0 103.68 0v-91.69a1.84 1.84 0 00-1.84-1.85z"
                  />
                </g>
              </g>
              <path
                className="heat_slide_7__cls-2"
                d="M1539 868.5V701.44s-259.26-16.74-316.08 7.84-215.39 164.86-215.39 164.86z"
              />
            </g>
            <g filter="url(#heat-shadow-slide-7)">
              <g id="heat_slide_7__Bottom_Left-4" data-name="Bottom Left">
                <g id="heat_slide_7__sky_1-2" data-name="sky 1">
                  <path
                    className="heat_slide_7__cls-2"
                    d="M195.11 655.5h77.5V478.58a4.16 4.16 0 00-.45-1.88l-28.08-56.44a5.83 5.83 0 00-5.29-3h-37.94c-3.17 0-5.74 2.16-5.74 4.84z"
                  />
                  <path
                    className="heat_slide_7__cls-2"
                    d="M195.11 724.11h77.5V477.6l-30.01-60.31h-47.49v306.82z"
                  />
                  <g clipPath="url(#heat_slide_7__clip-path-3)">
                    <path
                      className="heat_slide_7__cls-2"
                      d="M190.27 438.64h108.07V686.6H190.27z"
                    />
                    <g clipPath="url(#heat_slide_7__clip-path-4)">
                      <path
                        className="heat_slide_7__cls-19"
                        d="M190.27 438.64h102.68v14.23H190.27z"
                        id="heat_slide_7___Grid_Repeat_"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_7__cls-19"
                        d="M190.27 469.83h102.68v14.23H190.27z"
                        id="heat_slide_7___Grid_Repeat_2"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_7__cls-19"
                        d="M190.27 501.02h102.68v14.23H190.27z"
                        id="heat_slide_7___Grid_Repeat_3"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_7__cls-19"
                        d="M190.27 532.21h102.68v14.23H190.27z"
                        id="heat_slide_7___Grid_Repeat_4"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_7__cls-19"
                        d="M190.27 563.41h102.68v14.23H190.27z"
                        id="heat_slide_7___Grid_Repeat_5"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_7__cls-19"
                        d="M190.27 594.6h102.68v14.23H190.27z"
                        id="heat_slide_7___Grid_Repeat_6"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_7__cls-19"
                        d="M190.27 625.79h102.68v14.23H190.27z"
                        id="heat_slide_7___Grid_Repeat_7"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_7__cls-19"
                        d="M190.27 656.98h102.68v14.23H190.27z"
                        id="heat_slide_7___Grid_Repeat_8"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                    </g>
                  </g>
                  <rect
                    className="heat_slide_7__cls-2"
                    x={223.81}
                    y={356.39}
                    width={5.81}
                    height={79.08}
                    rx={2.66}
                  />
                  <rect
                    className="heat_slide_7__cls-2"
                    x={200.84}
                    y={407.93}
                    width={35.03}
                    height={17.18}
                    rx={3.47}
                  />
                  <rect
                    className="heat_slide_7__cls-2"
                    x={209.64}
                    y={391.19}
                    width={7.75}
                    height={26.89}
                    rx={2.66}
                  />
                </g>
                <path
                  className="heat_slide_7__cls-2"
                  d="M225.81 789.89S495.56 658 488 641.82-2.21 569.44-2.21 569.44L9.55 805.29z"
                />
                <path
                  className="heat_slide_7__cls-2"
                  d="M426.48 607.47a1.89 1.89 0 012.1 1.17.74.74 0 010 .14l2.42 12.14 9.84-19.53a1.91 1.91 0 013.6.77.24.24 0 010 .12l-1.58 20.61 9-8.59a.8.8 0 01.19-.13 1.87 1.87 0 012.09.37 2 2 0 01.49 2.12l-6.13 17.25a.7.7 0 01-.74.46l-23.7-3.37a.69.69 0 01-.58-.74l1.39-20.94a2 2 0 011.61-1.85zM329.2 533h-2.58c-.92-3.19-4.31-5.57-8.37-5.57h-6.53a7.3 7.3 0 00-7.08 5.57h-2.18a7.08 7.08 0 00-7.07 7.09v2h42.16v-2c0-3.87-3.74-7.09-8.35-7.09zM344.16 524.08h2.45v25.9h-2.45z"
                />
                <path
                  className="heat_slide_7__cls-2"
                  d="M351.26 526.11v-2.29h1.61v2.29c0 4.87-4.12 7.33-8.28 7.38v-1.41c3.41-.08 6.67-2.08 6.67-5.97zM338.65 522.84v-2.45h-1.44v2.45c0 5.19 3.67 7.82 7.38 7.87v-1.51a6 6 0 01-5.94-6.36z"
                />
                <path
                  className="heat_slide_7__cls-2"
                  d="M333.31 509.35h1.39v-.08a4.44 4.44 0 014.43-4.45h10.07a4.44 4.44 0 014.43 4.45v.08h3.72a4.08 4.08 0 014.07 4.09v6.94a4.08 4.08 0 01-4.07 4.08h-24a4.08 4.08 0 01-4.07-4.08v-6.94a4.08 4.08 0 014.03-4.09zM295.39 546.71v87.36h61.69v-87.36zm51.1 25a3.43 3.43 0 01-3.42 3.43h-1.35a3.43 3.43 0 01-3.42-3.43V561a3.42 3.42 0 013.42-3.43h1.35a3.43 3.43 0 013.42 3.43z"
                />
                <rect
                  className="heat_slide_7__cls-2"
                  x={290.55}
                  y={540.25}
                  width={68.94}
                  height={9.15}
                  rx={3.26}
                />
              </g>
              <g id="heat_slide_7__chinese_house" data-name="chinese house">
                <path
                  className="heat_slide_7__cls-2"
                  d="M133.06 538.37v56.19H171v-56.19zm8.34 18.92c0 .88-.52 1.58-1.16 1.58h-1.43c-.64 0-1.16-.7-1.16-1.58v-12.06c0-.87.52-1.58 1.16-1.58h1.43c.64 0 1.16.71 1.16 1.58zm20.42 23.28c0 .88-.52 1.58-1.16 1.58h-1.43c-.64 0-1.16-.7-1.16-1.58V568.5c0-.87.52-1.58 1.16-1.58h1.43c.64 0 1.16.71 1.16 1.58z"
                />
                <path
                  className="heat_slide_7__cls-2"
                  d="M172.28 535.24h-41.85c-.53 0-1 .59-1 1.32v3.29c0 .72.43 1.31 1 1.31h41.85c.54 0 1-.59 1-1.31v-3.29c-.03-.73-.46-1.32-1-1.32zM176.85 531.22l-8.19-8.22-4.36-9.58a.94.94 0 00-.82-.63h-24.66a1 1 0 00-.83.63l-4.26 9.5-8.2 8.27a1.63 1.63 0 00-.34 1.47 1.05 1.05 0 00.92.91h50.17a1.05 1.05 0 00.92-.91 1.6 1.6 0 00-.35-1.44z"
                />
                <path
                  className="heat_slide_7__cls-2"
                  d="M179.24 529.89h-55.77c-.53 0-1 .59-1 1.32v5.84c0 .73.43 1.32 1 1.32h55.77c.54 0 1-.59 1-1.32v-5.84c-.03-.73-.46-1.32-1-1.32zM165.71 509.82h-29.52c-.53 0-1 .59-1 1.32v2.35c0 .72.43 1.31 1 1.31h29.52c.53 0 1-.59 1-1.31v-2.35c-.03-.73-.47-1.32-1-1.32z"
                />
              </g>
              <g id="heat_slide_7__chinese_house-2" data-name="chinese house">
                <path
                  className="heat_slide_7__cls-2"
                  d="M65.32 532.89v56.19h38v-56.19zm8.34 18.92c0 .88-.52 1.58-1.16 1.58h-1.43c-.64 0-1.16-.7-1.16-1.58v-12.07c0-.87.52-1.58 1.16-1.58h1.43c.64 0 1.16.71 1.16 1.58zm20.42 23.28c0 .88-.52 1.58-1.16 1.58h-1.43c-.64 0-1.16-.7-1.16-1.58V563c0-.87.52-1.58 1.16-1.58h1.43c.64 0 1.16.71 1.16 1.58z"
                />
                <path
                  className="heat_slide_7__cls-2"
                  d="M104.54 529.76H62.69c-.53 0-1 .59-1 1.32v3.29c0 .72.44 1.31 1 1.31h41.85c.53 0 1-.59 1-1.31v-3.29c-.03-.73-.47-1.32-1-1.32zM109.11 525.74l-8.19-8.19-4.36-9.55a1 1 0 00-.82-.62H71.08a1 1 0 00-.83.63L66 517.47l-8.2 8.27a1.63 1.63 0 00-.34 1.47 1.05 1.05 0 00.92.91h50.17a1.06 1.06 0 00.92-.91 1.6 1.6 0 00-.36-1.47z"
                />
                <path
                  className="heat_slide_7__cls-2"
                  d="M111.5 524.41H55.73c-.53 0-1 .59-1 1.32v5.84c0 .73.44 1.32 1 1.32h55.77c.54 0 1-.59 1-1.32v-5.84c-.03-.73-.5-1.32-1-1.32zM98 504.34H68.45c-.53 0-1 .59-1 1.32V508c0 .72.44 1.31 1 1.31H98c.53 0 1-.59 1-1.31v-2.35c-.07-.72-.5-1.31-1-1.31z"
                />
              </g>
            </g>
            <g id="heat_slide_7__lake">
              <path
                filter="url(#heat-shadow-slide-7-inner)"
                className="heat_slide_7__cls-11"
                d="M101.18 842.23s68.3-36.5 141.68-73.24c65.73-32.91 119.35-66.48 86.39-75.45-20.87-5.68-159.87-10.07-211.61-15-121.18-11.68-188.78-21.38-188.78-21.38l10.4 204.1z"
              />
              <g
                mask="url(#heat_slide_7__mask-3)"
              >
                <path d="M101.18 842.23s68.3-36.5 141.68-73.24c65.73-32.91 119.35-66.48 86.39-75.45-20.87-5.68-159.87-10.07-211.61-15-121.18-11.68-188.78-21.38-188.78-21.38l10.4 204.1z" />
              </g>
              <g
                filter="url(#heat-shadow-slide-7-inner)"
              >
                <path
                  d="M-37.39 855.77S369 728.89 289.74 708.84c-40.53-10.26-183.07-3-239.26-11.6-117.88-18-261.26-26-261.26-26l11.85 204.09z"
                  fill="#6ddff3"
                />
                <g mask="url(#heat_slide_7__mask-4)">
                  <path d="M-37.39 855.77S369 728.89 289.74 708.84c-40.53-10.26-183.07-3-239.26-11.6-117.88-18-261.26-26-261.26-26l11.85 204.09z" />
                </g>
              </g>
            </g>
            <g filter="url(#heat-shadow-slide-7)">
              <path
                d="M405.4 645h-3.89a18.46 18.46 0 00-11.67 4.15 13.24 13.24 0 00-3-4.56l-1.84-1.95a1.24 1.24 0 00-1.73 0l-1.92 1.93a13.24 13.24 0 00-3 4.64 18.48 18.48 0 00-11.82-4.21h-3.89a1.22 1.22 0 00-1.22 1.22v3.91A18.6 18.6 0 00380 668.72h8.1a18.6 18.6 0 0018.55-18.61v-3.91a1.22 1.22 0 00-1.25-1.2z"
                fill="#ec987e"
              />
            </g>
          </g>

        </g>
      </g>
    </svg>
  );
}

export default HeatAnimationSlide7;
