import * as React from 'react';

function HeatNoAnimationSlide2(props) {
  return (
    <svg
      className="no-animation"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="heat_slide_2__clip-path">
          <path fill="none" d="M-.21 0h1440.43v797H-.21z" />
        </clipPath>
        <clipPath id="heat_slide_2__clip-path-2">
          <path
            className="heat_slide_2__cls-2"
            d="M453.96 441.67l16.8-2.71-.64 8.84-15.05 2.05-1.11-8.18z"
          />
        </clipPath>
        <clipPath id="heat_slide_2__clip-path-3">
          <path
            className="heat_slide_2__cls-2"
            d="M480.67 438.04l16.8-2.71-.64 8.84-15.04 2.05-1.12-8.18z"
          />
        </clipPath>
        <style>
          {
            '.heat_slide_2__cls-6{fill:none}.heat_slide_2__cls-2{fill:#e06371}.heat_slide_2__cls-5{opacity:.4;mix-blend-mode:multiply}.heat_slide_2__cls-6{stroke:#f8a547;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.94px}.heat_slide_2__cls-7{fill:#fbebca}.heat_slide_2__cls-8{fill:#c0566f}.heat_slide_2__cls-9{fill:#f26d6d}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="heat_slide_2__Layer_1" data-name="Layer 1">
          <g
            clipPath="url(#heat_slide_2__clip-path)"
            id="heat_slide_2__SLIDE_3_Varme_\xD8"
            data-name="SLIDE 3 Varme \xD8"
          >
            <g id="heat_slide_2__FLOAT">
              <path
                className="heat_slide_2__cls-6"
                d="M301.53 473.16c0-5.39 5.74-5.39 5.74-10.79s-5.74-5.4-5.74-10.79 5.74-5.4 5.74-10.8-5.74-5.4-5.74-10.79 5.74-5.4 5.74-10.8-5.74-5.41-5.74-10.81"
                id="heat_slide_2__heat_line"
                data-name="heat line"
              />
              <path
                className="heat_slide_2__cls-6"
                d="M671.72 395.23c0-5.4 5.73-5.4 5.73-10.79s-5.73-5.4-5.73-10.8 5.73-5.39 5.73-10.79-5.73-5.4-5.73-10.8 5.73-5.4 5.73-10.8-5.73-5.4-5.73-10.8"
                id="heat_slide_2__heat_line-2"
                data-name="heat line"
              />
              <path
                className="heat_slide_2__cls-6"
                d="M347.29 285c0-5.4 5.74-5.4 5.74-10.79s-5.74-5.4-5.74-10.79S353 258 353 252.59s-5.74-5.4-5.74-10.79S353 236.39 353 231s-5.74-5.4-5.74-10.8"
                id="heat_slide_2__heat_line-3"
                data-name="heat line"
              />
              <path
                className="heat_slide_2__cls-7 cloud"
                d="M377.51 298.79a12.32 12.32 0 0111.22-12.27 12.31 12.31 0 0111.91-9.2h1.62a12.31 12.31 0 0111.9 9.15h3.54a12.33 12.33 0 0112.3 12.32v1.28a12.17 12.17 0 01-.61 3.82 1.94 1.94 0 01-1.84 1.32H380a1.92 1.92 0 01-1.83-1.32 11.89 11.89 0 01-.61-3.82v-.64c0-.21-.05-.43-.05-.64z"
              />
              <path
                className="heat_slide_2__cls-7 cloud"
                d="M342.27 383.12a10.29 10.29 0 019.39-10.26 10.31 10.31 0 0110-7.7H363a10.31 10.31 0 0110 7.66h2.95a10.32 10.32 0 0110.31 10.3v1.08a10 10 0 01-.51 3.19 1.61 1.61 0 01-1.53 1.1h-39.91a1.61 1.61 0 01-1.53-1.1 10 10 0 01-.51-3.19v-.54-.54z"
              />
            </g>
            <path
              className="heat_slide_2__cls-2"
              d="M507.1 431.06l24.06-21.98 24.15 22.2v26.6H507.1v-26.82z"
            />
            <rect
              className="heat_slide_2__cls-8"
              x={536.21}
              y={432.62}
              width={10.57}
              height={22.37}
              rx={3.3}
            />
            <rect
              className="heat_slide_2__cls-8"
              x={515.3}
              y={433.48}
              width={8.57}
              height={10.2}
              rx={2.11}
            />
            <path
              className="heat_slide_2__cls-9"
              d="M532.91 404.39l26.58 24.43a2.11 2.11 0 010 3.15 2.58 2.58 0 01-3.43 0l-26.58-24.43a2.11 2.11 0 010-3.15 2.58 2.58 0 013.43 0z"
            />
            <path
              className="heat_slide_2__cls-9"
              d="M532.83 407.54L506.25 432a2.57 2.57 0 01-3.42 0 2.1 2.1 0 010-3.15l26.57-24.43a2.58 2.58 0 013.43 0 2.11 2.11 0 010 3.12z"
            />
            <path
              className="heat_slide_2__cls-2"
              d="M580.14 482.22c-2.7-18.94-8-33.08-75.77-33.63s-67.54 26-66.44 32.81 25 7.38 35 14.77 17.06 27.34 48.44 26 60.85-25.34 58.77-39.95z"
            />
            <path
              className="heat_slide_2__cls-2"
              d="M449.73 454a.76.76 0 00-.77.57v.06l-.25 4.81-5-6.92a.76.76 0 00-.88-.25.75.75 0 00-.5.77l1.78 7.83-4-2.76h-.09a.75.75 0 00-.8.27.77.77 0 00-.07.85l3.38 6.26a.28.28 0 00.32.13l9.16-2.73a.28.28 0 00.19-.32l-1.73-8a.74.74 0 00-.74-.57z"
            />
            {/* <path
              stroke="black"
              fill="none"
              d="M580.14 482.22c-2.7-18.94-8-33.08-75.77-33.63s-67.54
              26-66.44 32.81 25 7.38 35 14.77 17.06 27.34 48.44 26
               60.85-25.34 58.77-39.95z"
            /> */}
            <g
              id="heat_slide_2__small_car-2"
              data-name="small car"
              transform="translate(480.178 450.304) rotate(180)"
            >
              <g>
                <path
                  className="heat_slide_2__cls-2"
                  d="M450.59 446.44l.2 1.41a2.55 2.55 0 002.88 2.19l43.86-6a2.56 2.56 0 002.19-2.88l-.2-1.41a2.53 2.53 0 01-.87 2.29 2.56 2.56 0 00.87-2.29l-1.42-10.48-3.83-9.88a2 2 0 00-2.16-1.25l-18.84 2.56a2.09 2.09 0 00-1.5 1l-.25.43-5.86 9.87-.4.68-.71 1.19-8.68 1.18a6.76 6.76 0 00-5.79 7.61l.51 3.78a2.56 2.56 0 001.52 2 2.56 2.56 0 01-1.52-2zm29.6-16.29a1.29 1.29 0 01-1.11 1.45l-11.43 1.55 6-10.4 4.17-.56a1.29 1.29 0 011.45 1.11zm14.23-.74l-10.25 1.39a1 1 0 01-1.16-.89l-1-7.14a1 1 0 01.89-1.17l8.62-1.17a1 1 0 011 .64l2.61 7a1 1 0 01-.71 1.34z"
                />
                <path
                  className="heat_slide_2__cls-2"
                  d="M453.96 441.67l16.8-2.71-.64 8.84-15.05 2.05-1.11-8.18z"
                />
                <g clipPath="url(#heat_slide_2__clip-path-2)">
                  <ellipse
                    className="heat_slide_2__cls-2"
                    cx={462.84}
                    cy={448}
                    rx={7.14}
                    ry={6.91}
                    transform="rotate(-7.67 463.178 448.304)"
                  />
                </g>
                <g id="heat_slide_2__hjul">
                  <ellipse
                    className="heat_slide_2__cls-2"
                    cx={462.83}
                    cy={447.95}
                    rx={6.27}
                    ry={6.06}
                    transform="rotate(-8.1 470.653 464.512)"
                  />
                  <ellipse
                    className="heat_slide_2__cls-2"
                    cx={462.84}
                    cy={448}
                    rx={3.86}
                    ry={3.73}
                    transform="rotate(-7.67 463.178 448.304)"
                  />
                </g>
                <path
                  className="heat_slide_2__cls-2"
                  d="M480.67 438.04l16.8-2.71-.64 8.84-15.04 2.05-1.12-8.18z"
                />
                <g clipPath="url(#heat_slide_2__clip-path-3)">
                  <ellipse
                    className="heat_slide_2__cls-2"
                    cx={489.55}
                    cy={444.37}
                    rx={7.14}
                    ry={6.91}
                    transform="rotate(-7.67 489.873 444.624)"
                  />
                </g>
                <g id="heat_slide_2__hjul-2" data-name="hjul">
                  <ellipse
                    className="heat_slide_2__cls-2"
                    cx={489.54}
                    cy={444.32}
                    rx={6.27}
                    ry={6.06}
                    transform="rotate(-7.67 489.873 444.624)"
                  />
                  <ellipse
                    className="heat_slide_2__cls-2"
                    cx={489.55}
                    cy={444.37}
                    rx={3.86}
                    ry={3.73}
                    transform="rotate(-7.67 489.873 444.624)"
                  />
                </g>
                <path
                  className="heat_slide_2__cls-2"
                  d="M457.7 436.7l-3.48.48a3.32 3.32 0 00-2.85 3.74l5.88-.8a.9.9 0 00.78-1zM464.63 434.51l.98-.13.41-.71 6.71-11.69.36-.62-.45-.17-.53.88-7.04 11.73-.44.71z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HeatNoAnimationSlide2;
