import * as React from 'react';

function HeatNoAnimationSlide4(props) {
  return (
    <svg
      className="no-animation"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="heat_slide_4__clip-path">
          <path
            className="heat_slide_4__cls-1"
            d="M275.19 541.17l36.08-9.64a15.94 15.94 0 0011.15-19.44 15.61 15.61 0 00-19.11-11.34l-36.08 9.64a16 16 0 00-11.16 19.44 15.63 15.63 0 0019.12 11.34z"
          />
        </clipPath>
        <clipPath id="heat_slide_4__clip-path-2">
          <path
            className="heat_slide_4__cls-1"
            d="M300.94 513.15a8.32 8.32 0 017.87-8.82 8.46 8.46 0 018.64 8.19 8.31 8.31 0 01-7.87 8.82 8.46 8.46 0 01-8.64-8.19"
          />
        </clipPath>
        <style>
          {
            '.heat_slide_4__cls-1{fill:none}.heat_slide_4__cls-4{fill:#94fffa}.heat_slide_4__cls-5{fill:#c5fff4}.heat_slide_4__cls-6{fill:#eb826a}.heat_slide_4__cls-7{fill:#fbec6d}.heat_slide_4__cls-9{fill:#414042}.heat_slide_4__cls-12{fill:#f99c43}.heat_slide_4__cls-14{fill:#c6baff}.heat_slide_4__cls-15{fill:#eed7ff}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="heat_slide_4__Layer_1" data-name="Layer 1">
          <g id="heat_slide_4___5_Gr\xF8n_Vegation" data-name="5 Gr\xF8n Vegation">
            <g id="heat_slide_4__FLOATING_OBJ" data-name="FLOATING OBJ">
              <g id="heat_slide_4__water_steam" data-name="water steam">
                <g>
                  <circle
                    className="heat_slide_4__cls-4"
                    cx={345.86}
                    cy={219.76}
                    r={6.66}
                  />
                  <circle
                    className="heat_slide_4__cls-5"
                    cx={345.86}
                    cy={219.76}
                    r={4.62}
                  />
                </g>
                <g
                  id="heat_slide_4__water_drop-2"
                  data-name="water_drop"
                >
                  <circle
                    className="heat_slide_4__cls-4"
                    cx={382.32}
                    cy={164.32}
                    r={6.66}
                  />
                  <circle
                    className="heat_slide_4__cls-5"
                    cx={382.32}
                    cy={164.32}
                    r={4.62}
                  />
                </g>
                <g
                  id="heat_slide_4__water_drop-3"
                  data-name="water_drop"
                >
                  <circle
                    className="heat_slide_4__cls-4"
                    cx={511.55}
                    cy={131.32}
                    r={6.66}
                  />
                  <circle
                    className="heat_slide_4__cls-5"
                    cx={511.55}
                    cy={131.32}
                    r={4.62}
                  />
                </g>
                <g
                  id="heat_slide_4__water_drop-4"
                  data-name="water_drop"
                >
                  <circle
                    className="heat_slide_4__cls-4"
                    cx={441.61}
                    cy={137.98}
                    r={6.66}
                  />
                  <circle
                    className="heat_slide_4__cls-5"
                    cx={441.61}
                    cy={137.98}
                    r={4.62}
                  />
                </g>
                <g
                  id="heat_slide_4__water_drop-5"
                  data-name="water_drop"
                >
                  <circle
                    className="heat_slide_4__cls-4"
                    cx={640.67}
                    cy={251.28}
                    r={6.66}
                  />
                  <circle
                    className="heat_slide_4__cls-5"
                    cx={640.67}
                    cy={251.28}
                    r={4.62}
                  />
                </g>
                <g
                  id="heat_slide_4__water_drop-6"
                  data-name="water_drop"
                >
                  <circle
                    className="heat_slide_4__cls-4"
                    cx={660.9}
                    cy={315.46}
                    r={6.66}
                  />
                  <circle
                    className="heat_slide_4__cls-5"
                    cx={660.9}
                    cy={315.46}
                    r={4.62}
                  />
                </g>
                <g
                  id="heat_slide_4__water_drop-7"
                  data-name="water_drop"
                >
                  <circle
                    className="heat_slide_4__cls-4"
                    cx={618.62}
                    cy={188.56}
                    r={6.66}
                  />
                  <circle
                    className="heat_slide_4__cls-5"
                    cx={618.62}
                    cy={188.56}
                    r={4.62}
                  />
                </g>
                <g
                  id="heat_slide_4__vand_damp"
                  data-name="vand damp"
                >
                  <circle
                    className="heat_slide_4__cls-4"
                    cx={574.65}
                    cy={148.91}
                    r={6.66}
                  />
                  <circle
                    className="heat_slide_4__cls-5"
                    cx={574.65}
                    cy={148.91}
                    r={4.62}
                  />
                </g>
              </g>
              <g id="heat_slide_4__bee-2" data-name="bee">
                <path
                  className="heat_slide_4__cls-6"
                  d="M300.86 542.62l2.87-.72.02-8.51-2.87.72-.02 8.51zM295.41 543.98l2.87-.72.02-8.51-2.87.72-.02 8.51zM282.79 547.13l2.87-.72.02-8.51-2.86.71-.03 8.52zM277.34 548.48l2.87-.71.03-8.51-2.87.71-.03 8.51z"
                />
                <path
                  className="heat_slide_4__cls-7"
                  d="M275.19 541.17l36.08-9.64a15.94 15.94 0 0011.15-19.44 15.61 15.61 0 00-19.11-11.34l-36.08 9.64a16 16 0 00-11.16 19.44 15.63 15.63 0 0019.12 11.34z"
                />
                <g clipPath="url(#heat_slide_4__clip-path)">
                  <path
                    className="heat_slide_4__cls-9"
                    d="M333.62 523.25a13.39 13.39 0 01-22.6 0l-1.49 1a15.15 15.15 0 0025.58 0z"
                  />
                </g>
                <g clipPath="url(#heat_slide_4__clip-path-2)">
                  <path
                    d="M300.94 513.15a8.32 8.32 0 017.87-8.82 8.46 8.46 0 018.64 8.19 8.31 8.31 0 01-7.87 8.82 8.46 8.46 0 01-8.64-8.19"
                    fill="#f1f2f2"
                  />
                  <path
                    className="heat_slide_4__cls-9"
                    d="M309.2 512.84a8.31 8.31 0 017.87-8.82 8.45 8.45 0 018.64 8.19 8.33 8.33 0 01-7.87 8.82 8.47 8.47 0 01-8.64-8.19"
                  />
                </g>
                <path
                  className="heat_slide_4__cls-12"
                  d="M279.01 540.15l22.01-5.89-7.95-30.78-22.02 5.89 7.96 30.78z"
                />
                <path
                  className="heat_slide_4__cls-6"
                  d="M238.39 484.19l2.75-.73a32.2 32.2 0 0139.39 23.37l-2.75.74a32.2 32.2 0 01-39.39-23.38z"
                />
                <path
                  d="M298.82 465.05l.72 2.8c4.53 17.51-5.76 35.44-23 40l-.73-2.8c-4.53-17.52 5.77-35.45 23-40.05z"
                  fill="#f5b1a6"
                />
              </g>
              {/* ----------------flies------------------ */}
              <g
                id="heat_slide_4__fly"
              >
                <g>
                  <rect
                    className="heat_slide_4__cls-12"
                    x={300.08}
                    y={264.87}
                    width={18.72}
                    height={6.66}
                    rx={3.33}
                    transform="rotate(-73.55 309.454 268.202)"
                  />
                </g>
                <g>
                  <rect
                    className="heat_slide_4__cls-7"
                    x={301.32}
                    y={259.53}
                    width={6.66}
                    height={18.72}
                    rx={3.33}
                    transform="rotate(-30 304.65 268.891)"
                  />
                </g>
                <rect
                  className="heat_slide_4__cls-7"
                  x={302.14}
                  y={273.71}
                  width={12.93}
                  height={4.58}
                  rx={2.29}
                  transform="rotate(-5.26 308.462 275.853)"
                />
              </g>
              <g
                id="heat_slide_4__fly-2"
                data-name="fly"
                transform="translate(-1000, -150)"
              >
                <g>
                  <rect
                    className="heat_slide_4__cls-14"
                    x={746.3}
                    y={371.72}
                    width={25.63}
                    height={9.13}
                    rx={4.56}
                    transform="rotate(-73.55 759.145 376.284)"
                  />
                </g>
                <g>
                  <rect
                    className="heat_slide_4__cls-15"
                    x={748}
                    y={364.42}
                    width={9.13}
                    height={25.63}
                    rx={4.56}
                    transform="rotate(-30 752.557 377.247)"
                  />
                </g>
                <rect
                  className="heat_slide_4__cls-14"
                  x={749.13}
                  y={383.84}
                  width={17.7}
                  height={6.28}
                  rx={3.14}
                  transform="rotate(-5.26 757.686 386.807)"
                />
              </g>
              <g
                id="heat_slide_4__fly-3"
                data-name="fly"
              >
                <g>
                  <rect
                    className="heat_slide_4__cls-14"
                    x={708.02}
                    y={331.83}
                    width={25.63}
                    height={9.13}
                    rx={4.56}
                    transform="rotate(-73.55 720.865 336.39)"
                  />
                </g>
                <g>
                  <rect
                    className="heat_slide_4__cls-15"
                    x={709.72}
                    y={324.53}
                    width={9.13}
                    height={25.63}
                    rx={4.56}
                    transform="rotate(-30 714.287 337.353)"
                  />
                </g>
                <rect
                  className="heat_slide_4__cls-14"
                  x={710.85}
                  y={343.94}
                  width={17.7}
                  height={6.28}
                  rx={3.14}
                  transform="rotate(-5.26 719.373 346.978)"
                />
              </g>
              <g id="heat_slide_4__wind">
                <g>
                  <path
                    className="heat_slide_4__cls-5"
                    d="M395.18 535c1.61 6.19-1.46 10.95-3.52 13.45a.5.5 0 01-.89-.33c.05-2.61-.11-5.91-1.65-8.13a7.2 7.2 0 00-8.21-2.37 7 7 0 00-4.31 6.29 8.92 8.92 0 002 5.38c4.64 6.06 12.75 7.94 19.2 3.52 9.94-6.82 6.95-24.36 1-33a.41.41 0 01.6-.55c3.9 3.34 8.21 6.64 10.5 11.37a29.69 29.69 0 012 18.33c-1.52 7.54-6.31 16.36-14.05 18.83a27.31 27.31 0 01-18.78-1.14 25.78 25.78 0 01-12.74-12.78c-2.93-6.17-4-14.08-.71-20.29 6.14-11.45 24.11-13.81 29.13 0a13.85 13.85 0 01.44 1.39"
                  />
                </g>
                <g>
                  <path
                    id="heat_slide_4__wind-2"
                    data-name="wind"
                    className="heat_slide_4__cls-5"
                    d="M584.81 579.93c-5.21-3.7-5.92-9.31-5.94-12.56a.5.5 0 01.89-.32c1.64 2 3.88 4.46 6.49 5.17a7.2 7.2 0 007.82-3.46 7 7 0 00-.75-7.59 8.88 8.88 0 00-5-2.83c-7.46-1.66-14.88 2.1-17 9.64-3.24 11.6 10.33 23.12 20.43 25.86a.41.41 0 01-.1.81c-5.14-.06-10.56.18-15.35-2a29.68 29.68 0 01-13.3-12.71c-3.68-6.76-5.67-16.59-1.33-23.45a27.25 27.25 0 0115.12-11.19 25.74 25.74 0 0118 1.59c6.21 2.86 12.14 8.2 13.59 15.1 2.65 12.72-9.6 26.07-22.31 18.73a13.64 13.64 0 01-1.23-.79"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HeatNoAnimationSlide4;
