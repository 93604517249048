import * as React from 'react';
import { motion } from 'framer-motion';

function HeatAnimationSlide6(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="heat_slide_6__clip-path">
          <path
            d="M468 606.94a9.63 9.63 0 01-13.61-1.09 9.75 9.75 0 1113.61 1.09"
            fill="none"
          />
        </clipPath>
        <style>
          {
            '.heat_slide_6__cls-3{opacity:.4;mix-blend-mode:multiply}.heat_slide_6__cls-4{fill:#f08e76}.heat_slide_6__cls-6{fill:#72cbf1}.heat_slide_6__cls-7{fill:#74f3de}.heat_slide_6__cls-8{fill:#20f6ff}.heat_slide_6__cls-9{clip-path:url(#heat_slide_6__clip-path)}.heat_slide_6__cls-11{fill:#fced6f}.heat_slide_6__cls-13{fill:#f4cb5d}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="heat_slide_6__Layer_1" data-name="Layer 1">
          <g
            id="heat_slide_6__SLIDE_7_Gr\xF8nne_Kroner_k\xF8ler"
            data-name="SLIDE 7 Gr\xF8nne Kroner k\xF8ler"
          >
            <g id="heat_slide_6__drop">
              <path
                className="heat_slide_6__cls-4"
                d="M167.76 417.75l1.24 55.11a.53.53 0 010 .13c-.12.85 0 9.76-10.32 9.24s3.44-47.86 7.56-64.66a.8.8 0 011.52.18z"
              />
            </g>
            <g id="heat_slide_6__drop-2" data-name="drop">
              <path
                className="heat_slide_6__cls-4"
                d="M416 219.87a.76.76 0 00-.54 1.4l62.63 38.86s28.91 18.36 41.24-.13c11-16.37-82.48-36-103.33-40.13z"
              />
            </g>
            <g id="heat_slide_6__FLOAT_OBJECTS" data-name="FLOAT OBJECTS">
              {/*  ---------------- flies ----------------- */}
              <g
                id="heat_slide_6__fly"
                transform="translate(-1000, -150)"
              >
                <animateMotion
                  dur="12s"
                  keyPoints="1;0"
                  keyTimes="0;1"
                  calcMode="linear"
                  repeatCount="indefinite"
                  path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
                />
                <motion.path
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                  className="heat_slide_6__cls-6"
                  d="M281.69 456.15l-4.6-15.64a4.49 4.49 0 00-5.58-3.05 4.52 4.52 0 00-3 5.6l4.6 15.64a4.49 4.49 0 005.58 3.05 4.52 4.52 0 003-5.6z"
                />
                <motion.path
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                  className="heat_slide_6__cls-7"
                  d="M281.37 459.86l8.12-14.12a4.53 4.53 0 00-1.64-6.16 4.5 4.5 0 00-6.14 1.65l-8.12 14.12a4.51 4.51 0 001.64 6.16 4.48 4.48 0 006.14-1.65z"
                />
                <rect
                  className="heat_slide_6__cls-7"
                  x={273.1}
                  y={451.45}
                  width={6.2}
                  height={17.42}
                  rx={3.09}
                  transform="rotate(-84.72 276.222 460.166)"
                />
              </g>
              <g
                id="heat_slide_6__fly-2"
                data-name="fly"
                transform="translate(-1000, -150)"
              >
                <animateMotion
                  dur="8s"
                  repeatCount="indefinite"
                  path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
                />
                <motion.path
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                  className="heat_slide_6__cls-6"
                  d="M327.63 541.32L324.31 530a3.26 3.26 0 00-4-2.21 3.27 3.27 0 00-2.2 4.05l3.33 11.3a3.25 3.25 0 004 2.21 3.26 3.26 0 002.19-4.03z"
                />
                <motion.path
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                  className="heat_slide_6__cls-7"
                  d="M327.39 544l5.87-10.2a3.26 3.26 0 00-1.18-4.45 3.25 3.25 0 00-4.44 1.19l-5.87 10.21a3.26 3.26 0 001.19 4.44 3.23 3.23 0 004.43-1.19z"
                />
                <rect
                  className="heat_slide_6__cls-7"
                  x={321.42}
                  y={537.93}
                  width={4.48}
                  height={12.59}
                  rx={2.23}
                  transform="rotate(-84.72 323.68 544.224)"
                />
              </g>
              <g
                id="heat_slide_6__fly-3"
                data-name="fly"
                transform="translate(-1000, -150)"
              >
                <animateMotion
                  dur="6s"
                  repeatCount="indefinite"
                  path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
                />
                <motion.path
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                  className="heat_slide_6__cls-6"
                  d="M235 487.49l-3.32-11.3a3.24 3.24 0 00-4-2.2 3.26 3.26 0 00-2.2 4l3.33 11.31a3.24 3.24 0 004 2.2 3.26 3.26 0 002.19-4.01z"
                />
                <motion.path
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                  className="heat_slide_6__cls-7"
                  d="M234.73 490.18L240.6 480a3.27 3.27 0 00-1.19-4.45 3.24 3.24 0 00-4.43 1.19l-5.87 10.21a3.26 3.26 0 001.18 4.45 3.25 3.25 0 004.44-1.22z"
                />
                <rect
                  className="heat_slide_6__cls-7"
                  x={228.76}
                  y={484.1}
                  width={4.48}
                  height={12.59}
                  rx={2.23}
                  transform="rotate(-84.72 231.008 490.4)"
                />
              </g>
              <g id="heat_slide_6__water_drops-2" data-name="water drops">
                <motion.ellipse
                  animate={{
                    y: [0, -250],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 6, repeat: Infinity,
                  }}
                  id="heat_slide_6__water_drop"
                  className="heat_slide_6__cls-8"
                  cx={290.09}
                  cy={662.95}
                  rx={5.69}
                  ry={5.71}
                />
                <motion.ellipse
                  animate={{
                    y: [0, -250],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 6, delay: 1, repeat: Infinity,
                  }}
                  id="heat_slide_6__water_drop-2"
                  data-name="water_drop"
                  className="heat_slide_6__cls-8"
                  cx={461.04}
                  cy={662.95}
                  rx={5.69}
                  ry={5.71}
                />
                <motion.ellipse
                  animate={{
                    y: [0, -250],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 6, delay: 2, repeat: Infinity,
                  }}
                  id="heat_slide_6__water_drop-3"
                  data-name="water_drop"
                  className="heat_slide_6__cls-8"
                  cx={393.38}
                  cy={674.37}
                  rx={5.69}
                  ry={5.71}
                />
                <motion.ellipse
                  animate={{
                    y: [0, -250],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 6, delay: 3, repeat: Infinity,
                  }}
                  id="heat_slide_6__water_drop-4"
                  data-name="water_drop"
                  className="heat_slide_6__cls-8"
                  cx={521.63}
                  cy={668.66}
                  rx={5.69}
                  ry={5.71}
                />
                <motion.g
                  id="heat_slide_6__duck-2"
                  data-name="duck"
                  animate={{
                    x: [0, -140, 0],
                  }}
                  transition={{
                    duration: 10, repeat: Infinity,
                  }}
                >
                  <path
                    className="heat_slide_6__cls-8"
                    d="M447.59 649.86h32.2V599a16.1 16.1 0 00-16.1-16.1 16.1 16.1 0 00-16.1 16.1zM447.58 649.86h87.63v.14a35.88 35.88 0 01-35.89 35.88h-40.17a11.57 11.57 0 01-11.57-11.57z"
                  />
                  <path
                    className="heat_slide_6__cls-8"
                    d="M499.06 649.86h36.15v.14a35.88 35.88 0 01-35.89 35.88h-36.14v-.13a35.89 35.89 0 0135.88-35.89zM447.59 633.82h32.2v16.05h-32.2z"
                  />
                  <g className="heat_slide_6__cls-9">
                    <path
                      className="heat_slide_6__cls-8"
                      d="M468 606.94a9.63 9.63 0 01-13.61-1.09 9.75 9.75 0 1113.61 1.09"
                    />
                    <path
                      className="heat_slide_6__cls-8"
                      d="M461.8 599.57a9.63 9.63 0 01-13.61-1.09 9.75 9.75 0 1113.61 1.09"
                    />
                  </g>
                  <path
                    className="heat_slide_6__cls-8"
                    d="M432.06 606.54h15.53v-6h-15.53a3 3 0 00-3 3 3 3 0 003 3z"
                  />
                  <path
                    className="heat_slide_6__cls-8"
                    d="M432.06 606.54h9.59a5.94 5.94 0 005.94-5.94h-15.53a3 3 0 00-3 3 3 3 0 003 2.94z"
                  />
                  <g>
                    <path
                      d="M447.59 649.86h32.2V599a16.1 16.1 0 00-16.1-16.1 16.1 16.1 0 00-16.1 16.1z"
                      fill="#f99c43"
                    />
                    <path
                      className="heat_slide_6__cls-11"
                      d="M447.58 649.86h87.63v.14a35.88 35.88 0 01-35.89 35.88h-40.17a11.57 11.57 0 01-11.57-11.57z"
                    />
                    <path
                      d="M499.06 649.86h36.15v.14a35.88 35.88 0 01-35.89 35.88h-36.14v-.13a35.89 35.89 0 0135.88-35.89z"
                      fill="#f7dd41"
                    />
                    <path
                      className="heat_slide_6__cls-13"
                      d="M447.59 633.82h32.2v16.05h-32.2z"
                    />
                    <g className="heat_slide_6__cls-9">
                      <path
                        d="M468 606.94a9.63 9.63 0 01-13.61-1.09 9.75 9.75 0 1113.61 1.09"
                        fill="#f1f2f2"
                      />
                      <path
                        d="M461.8 599.57a9.63 9.63 0 01-13.61-1.09 9.75 9.75 0 1113.61 1.09"
                        fill="#414042"
                      />
                    </g>
                    <path
                      className="heat_slide_6__cls-13"
                      d="M432.06 606.54h15.53v-6h-15.53a3 3 0 00-3 3 3 3 0 003 3z"
                    />
                    <path
                      className="heat_slide_6__cls-11"
                      d="M432.06 606.54h9.59a5.94 5.94 0 005.94-5.94h-15.53a3 3 0 00-3 3 3 3 0 003 2.94z"
                    />
                  </g>
                </motion.g>
                <motion.ellipse
                  animate={{
                    y: [0, -250],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 6, delay: 4, repeat: Infinity,
                  }}
                  id="heat_slide_6__water_drop-5"
                  data-name="water_drop"
                  className="heat_slide_6__cls-8"
                  cx={438.34}
                  cy={702.91}
                  rx={5.69}
                  ry={5.71}
                />
                <motion.ellipse
                  animate={{
                    y: [0, -250],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 6, delay: 5, repeat: Infinity,
                  }}
                  id="heat_slide_6__water_drop-6"
                  data-name="water_drop"
                  className="heat_slide_6__cls-8"
                  cx={346.3}
                  cy={697.2}
                  rx={5.69}
                  ry={5.71}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HeatAnimationSlide6;
