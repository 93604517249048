import * as React from 'react';
import { motion } from 'framer-motion';

function HeatAnimationSlide3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <filter id="heat-shadow-slide-3" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <clipPath id="heat_slide_3__clip-path">
          <path
            className="heat_slide_3__cls-1"
            d="M1059 637.48a48.64 48.64 0 1139.45-56.36 48.7 48.7 0 01-39.45 56.36zm-12.58-71.33a23.79 23.79 0 1027.58 19.29 23.81 23.81 0 00-27.59-19.29z"
          />
        </clipPath>
        <clipPath id="heat_slide_3__clip-path-2">
          <path
            className="heat_slide_3__cls-1"
            d="M950.5 509.81c4.8 8.32 2.55 18.62-5 23s-17.68 1.19-22.5-7.15-2.54-18.62 5-23 17.64-1.18 22.44 7.15"
          />
        </clipPath>
        <clipPath id="heat_slide_3__clip-path-3">
          <path
            className="heat_slide_3__cls-1"
            d="M341 507.51a18.18 18.18 0 001.53 26.06c7.76 6.57 19.66 5.56 26.58-2.26a18.18 18.18 0 00-1.53-26.06c-7.77-6.57-19.67-5.56-26.58 2.26"
          />
        </clipPath>
        <clipPath id="heat_slide_3__clip-path-4">
          <rect
            className="heat_slide_3__cls-1"
            x={295.09}
            y={534.81}
            width={37.17}
            height={135.1}
            rx={18.59}
            transform="rotate(-77.8 313.705 602.365)"
          />
        </clipPath>
        <clipPath id="heat_slide_3__clip-path-5">
          <path
            className="heat_slide_3__cls-1"
            d="M492.29 539.65h342.64v89.75H492.29z"
          />
        </clipPath>
        <clipPath id="heat_slide_3__clip-path-6">
          <path
            className="heat_slide_3__cls-1"
            d="M629.67 597.59c6.51-1.9 18.88 1 32.49 5.69l18.26-11.42a3 3 0 014.21 3.7l-5.26 14.27c22 9 42.4 19.57 42.4 19.57-1.11-.86-110.58 0-110.58 0s-1.09-26.11 18.48-31.81z"
          />
        </clipPath>
        <clipPath id="heat_slide_3__clip-path-7">
          <path
            className="heat_slide_3__cls-1"
            d="M617.63 608.24l12.68 20.97 20.04-13.26-12.68-20.98-20.04 13.27z"
          />
        </clipPath>
        <clipPath id="heat_slide_3__clip-path-9">
          <path
            className="heat_slide_3__cls-1"
            d="M629 603.79a9.15 9.15 0 0112.95 3.05A10.11 10.11 0 01639 620.4a9.15 9.15 0 01-13-3 10.1 10.1 0 013-13.61"
          />
        </clipPath>
        <linearGradient
          id="heat_slide_3__linear-gradient"
          x1={320.53}
          y1={479.83}
          x2={392.18}
          y2={479.83}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#72cbf1" />
          <stop offset={0.51} stopColor="#71cef1" />
          <stop offset={0.82} stopColor="#6fd6f2" />
          <stop offset={1} stopColor="#6cdff3" />
        </linearGradient>
        <style>
          {
            '.heat_slide_3__cls-1{fill:none}.heat_slide_3__cls-9{opacity:.5}.heat_slide_3__cls-4{fill:#86a3ce}.heat_slide_3__cls-8{fill:#f8a444}.heat_slide_3__cls-9{fill:#ffe0ab}.heat_slide_3__cls-11{fill:#f1f2f2}.heat_slide_3__cls-12{fill:#414042}.heat_slide_3__cls-26{fill:#f9bd74}.heat_slide_3__cls-18{fill:#308462}.heat_slide_3__cls-19{fill:#44a957}.heat_slide_3__cls-22{fill:#50c766}.heat_slide_3__cls-24{fill:#f8a547}.heat_slide_3__cls-31{clip-path:url(#heat_slide_3__clip-path-7)}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="heat_slide_3__Layer_1" data-name="Layer 1">
          <g
            filter="url(#heat-shadow-slide-3)"
            id="heat_slide_3__float_objects"
            data-name="float objects"
          >
            <motion.g
              animate={{
                rotate: [0, 0, -20, -20, 0, 0, 0],
              }}
              transition={{
                duration: 30,
                times: [0, 0.45, 0.50, 0.60, 0.65, 1, 1],
                repeat: Infinity,
              }}
              style={{
                transformBox: 'fill-box',
                originY: 'bottom',
                originX: 'center',
              }}
            >
              <rect
                className="heat_slide_3__cls-4"
                x={1000.57}
                y={620.32}
                width={7.59}
                height={47.65}
                rx={3.8}
              />
              <rect
                className="heat_slide_3__cls-4"
                x={963.77}
                y={640.35}
                width={47.65}
                height={7.59}
                rx={3.8}
                transform="rotate(-77.44 987.54 644.152)"
              />
              <path
                d="M925.8 590.84l58.25-10.27L971.42 509a29.58 29.58 0 00-34.26-24 29.57 29.57 0 00-24 34.26z"
                fill="#74f3de"
              />
              <path
                d="M926.14 586.1l114.69-20.24a2.7 2.7 0 013.14 2.19 56.93 56.93 0 01-46.17 66l-26.57 4.69a36.44 36.44 0 01-42.23-29.6l-3.83-21.69a1.17 1.17 0 01.97-1.35z"
                fill="#71dbdb"
              />
              <g clipPath="url(#heat_slide_3__clip-path)">
                <path
                  className="heat_slide_3__cls-8"
                  d="M1059 637.48a48.64 48.64 0 1139.45-56.36 48.7 48.7 0 01-39.45 56.36zm-12.58-71.33a23.79 23.79 0 1027.58 19.29 23.81 23.81 0 00-27.59-19.29z"
                />
                <circle
                  className="heat_slide_3__cls-9"
                  cx={1098.71}
                  cy={563.23}
                  r={22.32}
                />
                <circle
                  className="heat_slide_3__cls-9"
                  cx={1074.13}
                  cy={634.81}
                  r={22.32}
                />
                <circle
                  className="heat_slide_3__cls-9"
                  cx={1003.05}
                  cy={616.09}
                  r={22.32}
                />
                <circle
                  className="heat_slide_3__cls-9"
                  cx={1016.98}
                  cy={550.82}
                  r={22.32}
                />
              </g>
              <g clipPath="url(#heat_slide_3__clip-path-2)">
                <path
                  className="heat_slide_3__cls-11"
                  d="M950.5 509.81c4.8 8.32 2.55 18.62-5 23s-17.68 1.19-22.5-7.15-2.54-18.62 5-23 17.64-1.18 22.44 7.15"
                />
                <path
                  className="heat_slide_3__cls-12"
                  d="M936.76 517.74c4.8 8.32 2.54 18.62-5 23s-17.64 1.18-22.44-7.15-2.55-18.62 5.05-23 17.63-1.18 22.44 7.15"
                />
              </g>
              <path
                className="heat_slide_3__cls-8"
                d="M920 536.77a.77.77 0 00-.9-.62l-15.49 2.73a.78.78 0 00-.64.82c0 .06.58 6.61 7.17 8a32.16 32.16 0 006.43.65 23.82 23.82 0 004.64-.39.77.77 0 00.6-.89z"
              />
              <path
                d="M916.53 523.76l3.36 19s-4.76-.73-9.1-.9a69.77 69.77 0 00-10.93.9s-2.32-8.83 4.22-13.53a30.7 30.7 0 0112.45-5.47z"
                strokeLinejoin="round"
                strokeLinecap="round"
                stroke="#f9bd74"
                strokeWidth={2.42}
                fill="#f9bd74"
              />
              <path
                d="M994.6 634.56c-32.39 4.44-34.12-23.31-33.6-24.68s.08-20 27.95-28.19 79.94-3.22 80.35.45-23.52 45.37-74.7 52.42z"
                fill="#60c4cc"
              />
              <path
                strokeWidth={3.23}
                stroke="#72cbf1"
                fill="#72cbf1"
                strokeLinejoin="round"
                strokeLinecap="round"
                d="M948.27 492.11l4.81-19.25-38.9 4.89-5.2 19.89 12.51-1.9 6.97-8.68 2.57 7.1 17.24-2.05z"
              />
              <path
                d="M983.8 445.27L967 528.84S963.91 545 952.38 545c-4.93 0-11.81-1.9-11.81-1.9"
                strokeWidth={9.2}
                stroke="#72cbf1"
                strokeLinejoin="round"
                strokeLinecap="round"
                fill="none"
              />
              <path
                strokeWidth={2.78}
                stroke="#72cbf1"
                fill="#72cbf1"
                strokeLinejoin="round"
                strokeLinecap="round"
                d="M970.82 503.85l-26.17-14.18 2.56-8.99 22.5 16.01 1.11 7.16z"
              />
            </motion.g>
            <motion.g
              animate={{
                rotate: [20, 20, 0, 0, 0, 0, 20],
              }}
              transition={{
                duration: 30,
                times: [0, 0.15, 0.20, 0.60, 0.65, 0.90, 1],
                repeat: Infinity,
              }}
              style={{
                transformBox: 'fill-box',
                originY: 'bottom',
                originX: 'center',
              }}
              id="heat_slide_3__bird_A-2"
              data-name="bird A"
            >
              <path
                className="heat_slide_3__cls-18"
                d="M311.4 647.1a3.24 3.24 0 01-3.6-2.92l-2.46-24.75a3.4 3.4 0 012.94-3.71 3.22 3.22 0 013.59 2.92l2.47 24.75a3.41 3.41 0 01-2.94 3.71zM325.33 647.1a3.23 3.23 0 01-3.6-2.92l-2.46-24.75a3.4 3.4 0 012.94-3.71 3.22 3.22 0 013.59 2.92l2.47 24.75a3.41 3.41 0 01-2.94 3.71z"
              />
              <path
                className="heat_slide_3__cls-19"
                d="M362.3 564.92l-42.2-1.21c-17.23-.49-33.5 13-36.36 30.21l-2 12.16a6.22 6.22 0 006.09 7.48l55.54 1.59c12.33.35 24-9.33 26-21.62l2.75-16.53a10 10 0 00-9.82-12.08z"
              />
              <path
                d="M414.37 561.67l-40.76-11.28a1.22 1.22 0 01-.83-1.53l14.82-52.19a1.25 1.25 0 011.52-.88l22.42 6.21c18.77 5.2 29.57 25 24.12 44.13l-1.1 3.89a16.57 16.57 0 01-20.19 11.65z"
                fill="#f4cb5d"
              />
              <path
                d="M436.6 557.45s-17-5.2-32.43-9.3-31.17-.64-31.17-.64 13.67-44.32 15.87-52l27.82 7.7c21.27 5.89 33.5 28.27 27.33 50a6.09 6.09 0 01-7.42 4.24z"
                fill="#fced6f"
              />
              <path
                className="heat_slide_3__cls-22"
                d="M366.31 574.9L307 558.49l15.7-55.28c3.79-13.34 17.44-21.22 30.5-17.61l35.64 9.87z"
              />
              <g clipPath="url(#heat_slide_3__clip-path-3)">
                <path
                  className="heat_slide_3__cls-11"
                  d="M341 507.51a18.18 18.18 0 001.53 26.06c7.76 6.57 19.66 5.56 26.58-2.26a18.18 18.18 0 00-1.53-26.06c-7.77-6.57-19.67-5.56-26.58 2.26"
                />
                <path
                  className="heat_slide_3__cls-12"
                  d="M355 519.41a18.19 18.19 0 001.53 26.06c7.77 6.58 19.67 5.57 26.58-2.25a18.18 18.18 0 00-1.53-26.06c-7.76-6.58-19.66-5.57-26.58 2.25"
                />
              </g>
              <path
                className="heat_slide_3__cls-22"
                d="M366.7 573.56l-41.42-11.47c-24.27-6.72-49.66 7.94-56.7 32.73l-2.08 7.33a8.56 8.56 0 005.82 10.67L312.53 624c20.4 5.65 41.75-6.67 47.67-27.52z"
              />
              <path
                className="heat_slide_3__cls-19"
                d="M288.5 617.06l-31-8.59a7.44 7.44 0 01-5.07-9.29l.94-3.32c8.17-28.76 37.61-45.76 65.76-38l31.56 8.73a4 4 0 012.7 4.94l-2.73 9.63c-7.75 27.21-35.57 43.27-62.16 35.9z"
              />
              <path
                className="heat_slide_3__cls-24"
                transform="rotate(-39.58 383.086 599.286)"
                d="M366.79 590.2h32.6v18.18h-32.6z"
              />
              <rect
                className="heat_slide_3__cls-24"
                x={390.04}
                y={581.83}
                width={15.74}
                height={44.33}
                rx={7.87}
                transform="rotate(-39.58 397.918 603.995)"
              />
              <rect
                className="heat_slide_3__cls-24"
                x={378.05}
                y={571.55}
                width={32.22}
                height={15.68}
                rx={7.67}
                transform="rotate(-70.59 394.192 579.402)"
              />
              <rect
                className="heat_slide_3__cls-24"
                x={384.79}
                y={577.46}
                width={32.22}
                height={15.68}
                rx={7.67}
                transform="rotate(-24.58 400.905 585.29)"
              />
              <g>
                <rect
                  className="heat_slide_3__cls-24"
                  x={243.22}
                  y={558.99}
                  width={17.75}
                  height={31.83}
                  rx={8.87}
                  transform="rotate(-32.8 252.138 574.94)"
                />
                <g clipPath="url(#heat_slide_3__clip-path-4)">
                  <rect
                    className="heat_slide_3__cls-24"
                    x={295.09}
                    y={534.81}
                    width={37.17}
                    height={135.1}
                    rx={18.59}
                    transform="rotate(-77.8 313.705 602.365)"
                  />
                  <circle
                    className="heat_slide_3__cls-26"
                    cx={265.76}
                    cy={580.04}
                    r={13.36}
                  />
                  <circle
                    className="heat_slide_3__cls-26"
                    cx={289.73}
                    cy={613.75}
                    r={13.36}
                  />
                  <circle
                    className="heat_slide_3__cls-26"
                    cx={320.22}
                    cy={589.22}
                    r={13.36}
                  />
                  <circle
                    className="heat_slide_3__cls-26"
                    cx={368.93}
                    cy={594.93}
                    r={13.36}
                  />
                  <circle
                    className="heat_slide_3__cls-26"
                    cx={348.04}
                    cy={625.76}
                    r={13.36}
                  />
                </g>
              </g>
              <path
                d="M391.84 474.88l-1.84-2.64a1.89 1.89 0 00-1.24-.77 1.93 1.93 0 00-1.41.36 59.36 59.36 0 01-25.76 10.8l-.66-3.24c-2.29-11.34-13-18.82-23.82-16.69a20.26 20.26 0 00-13 8.73 21.4 21.4 0 00-3.11 16.21l1.28 6.34a4.18 4.18 0 001.8 2.67 4 4 0 002.21.66 4.26 4.26 0 00.86-.09l34.06-7.37a1.44 1.44 0 00.41-.15c1.49-.22 3-.49 4.39-.8a65.29 65.29 0 0025.47-11.45 1.88 1.88 0 00.36-2.57z"
                fill="url(#heat_slide_3__linear-gradient)"
              />
            </motion.g>
          </g>
          <g
            clipPath="url(#heat_slide_3__clip-path-5)"
            id="heat_slide_3__fish_container"
            data-name="fish container"
          >
            <motion.g
              animate={{
                x: [-70, -70, 0, 70, 70, 0, -70],
                y: [0, 0, 70, 0, 0, 70, 0],
                // rotate: [0, 0, 180, 0, 0],
                scaleX: [1, 1, -1, -1, 1],
              }}
              transition={{
                duration: 30, repeat: Infinity,
              }}
              id="heat_slide_3__fish"
              filter="url(#heat-shadow-slide-3)"
            >
              <path
                className="heat_slide_3__cls-8"
                d="M629.67 597.59c6.51-1.9 18.88 1 32.49 5.69l18.26-11.42a3 3 0 014.21 3.7l-5.26 14.27c22 9 42.4 19.57 42.4 19.57-1.11-.86-110.58 0-110.58 0s-1.09-26.11 18.48-31.81zM756.23 609.78c5.52 1.73-2.2 19.62-2.2 19.62h-17.1s13.79-21.35 19.3-19.62z"
              />
              <g clipPath="url(#heat_slide_3__clip-path-6)">
                <path
                  className="heat_slide_3__cls-8"
                  d="M629.67 597.59c6.51-1.9 18.88 1 32.49 5.69l18.26-11.42a3 3 0 014.21 3.7l-5.26 14.27c22 9 42.4 19.57 42.4 19.57-1.11-.86-110.58 0-110.58 0s-1.09-26.11 18.48-31.81z"
                />
                <path
                  d="M612.25 621.38s5-.67 7.49 1.47 2.43 3.59 2.43 3.59"
                  stroke="#000"
                  strokeMiterlimit={10}
                  strokeWidth={2.87}
                  strokeLinecap="round"
                  fill="none"
                />
              </g>
              <g id="heat_slide_3__eye_frog" data-name="eye frog">
                <g className="heat_slide_3__cls-31">
                  <path
                    d="M629 603.79a9.15 9.15 0 0112.95 3.05A10.11 10.11 0 01639 620.4a9.15 9.15 0 01-13-3 10.1 10.1 0 013-13.61"
                    fill="#fff"
                  />
                  <g clipPath="url(#heat_slide_3__clip-path-9)">
                    <path d="M623.79 600.39a9.15 9.15 0 0113 3.05 10.11 10.11 0 01-2.96 13.56 9.16 9.16 0 01-13-3 10.11 10.11 0 012.92-13.56" />
                  </g>
                </g>
              </g>
            </motion.g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HeatAnimationSlide3;
