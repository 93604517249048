import * as React from 'react';
import { motion } from 'framer-motion';

function HeatAnimationSlide1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <filter id="heat-shadow-slide-1" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <clipPath id="heat_slide_1__clip-path">
          <path className="heat_slide_1__cls-1" d="M0 0v797h1440V0z" />
        </clipPath>
        <clipPath id="heat_slide_1__clip-path-2">
          <path
            className="heat_slide_1__cls-4"
            d="M832.98 617.69l-30.88-10.62-1.68 16.93 27.78 8.79 4.78-15.1z"
          />
        </clipPath>
        <clipPath id="heat_slide_1__clip-path-3">
          <path
            className="heat_slide_1__cls-4"
            d="M753.96 592.68l-30.87-10.62-1.69 16.93 27.78 8.79 4.78-15.1z"
          />
        </clipPath>
        <clipPath id="heat_slide_1__clip-path-4">
          <path
            className="heat_slide_1__cls-1"
            d="M377.75 560.92h-62.43V325.51l24.17-57.6h38.26v293.01z"
          />
        </clipPath>
        <clipPath id="heat_slide_1__clip-path-6">
          <path
            className="heat_slide_1__cls-1"
            d="M294.6 288.3h87.06v236.79H294.6z"
          />
        </clipPath>
        <clipPath id="heat_slide_1__clip-path-8">
          <path
            className="heat_slide_1__cls-2"
            d="M267.04 624.8l40.09 10.86-10.31 19.02-35.53-10.57 5.75-19.31z"
          />
        </clipPath>
        <clipPath id="heat_slide_1__clip-path-9">
          <path
            className="heat_slide_1__cls-2"
            d="M330.1 643.58l40.08 10.86-10.31 19.02-35.52-10.57 5.75-19.31z"
          />
        </clipPath>
        <clipPath id="heat_slide_1__clip-path-10">
          <path
            id="heat_slide_1__eyes"
            className="heat_slide_1__cls-3"
            d="M771.06 172.77a4.27 4.27 0 01-4.26 3.64 3.32 3.32 0 01-3.15-3.91 4.29 4.29 0 014.23-3.68 3.32 3.32 0 013.18 3.95z"
          />
        </clipPath>
        <linearGradient
          id="heat_slide_1__linear-gradient"
          x1={468.65}
          y1={612.5}
          x2={468.65}
          y2={266.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#db6271" />
          <stop offset={0.99} stopColor="#734366" />
        </linearGradient>
        <linearGradient
          id="heat_slide_1__linear-gradient-2"
          x1={480.25}
          y1={612.5}
          x2={480.25}
          y2={266.7}
          xlinkHref="#heat_slide_1__linear-gradient"
        />
        <linearGradient
          id="heat_slide_1__linear-gradient-3"
          x1={491.84}
          y1={612.5}
          x2={491.84}
          y2={266.7}
          xlinkHref="#heat_slide_1__linear-gradient"
        />
        <linearGradient
          id="heat_slide_1__linear-gradient-4"
          x1={503.44}
          y1={612.5}
          x2={503.44}
          y2={266.7}
          xlinkHref="#heat_slide_1__linear-gradient"
        />
        <linearGradient
          id="heat_slide_1__linear-gradient-5"
          x1={515.03}
          y1={612.5}
          x2={515.03}
          y2={266.7}
          xlinkHref="#heat_slide_1__linear-gradient"
        />
        <linearGradient
          id="heat_slide_1__linear-gradient-6"
          x1={526.63}
          y1={612.5}
          x2={526.63}
          y2={266.7}
          xlinkHref="#heat_slide_1__linear-gradient"
        />
        <style>
          {
            '.heat_slide_1__cls-1,.heat_slide_1__cls-33{fill:none}.heat_slide_1__cls-2{fill:#875387}.heat_slide_1__cls-3{fill:#fff}.heat_slide_1__cls-4{fill:#e06371}.heat_slide_1__cls-7{opacity:.3}.heat_slide_1__cls-10,.heat_slide_1__cls-7{mix-blend-mode:multiply}.heat_slide_1__cls-10{opacity:.4}.heat_slide_1__cls-13{fill:#f26d6d}.heat_slide_1__cls-21{fill:#c05670}.heat_slide_1__cls-26{fill:#8e8bcd}.heat_slide_1__cls-27{fill:#fbebca}.heat_slide_1__cls-28{fill:#c6baff}.heat_slide_1__cls-29{fill:#eed7ff}.heat_slide_1__cls-30{fill:#f47e67}.heat_slide_1__cls-31{fill:#f9bd74}.heat_slide_1__cls-33{stroke:#f8a547;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.94px}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="heat_slide_1__Layer_1" data-name="Layer 1">
          <g
            clipPath="url(#heat_slide_1__clip-path)"
            id="heat_slide_1__SLIDE_2_Byens_Klima"
            data-name="SLIDE 2 Byens Klima"
          >
            <g
              id="heat_slide_1__truck"
              transform="rotate(-16 -1830, 3107.78)"
            >
              <animateMotion
                dur="46s"
                rotate="auto"
                repeatCount="indefinite"
                path="M-326.46,723.67l175.4-56.07,121.73-42.54s324.2-105,494.53-94.47S820.54,698,1047.65,644.15c178.65-42.36,418.53-164.44,421.68-169.72L1737.87,340"
              />
              <path
                className="heat_slide_1__cls-4"
                d="M729.1 536.12l-11.75 37.11a7.57 7.57 0 004.15 9.2l-2.29 7.24a5.48 5.48 0 003.57 6.87l75.46 23.88-.2.63a2.16 2.16 0 001.46 2.7l28.8 9.12a2.17 2.17 0 002.75-1.37l9.4-29.71a2.11 2.11 0 00-.05-1.38l-.17-.43-8.71-22.28-.5-1.26a2.23 2.23 0 00-1.39-1.29l-13.41-4.24a2.18 2.18 0 00-2.75 1.37l2.72-8.59a7.56 7.56 0 00-4.92-9.49l-72.69-23a7.55 7.55 0 00-9.48 4.92zm86.88 41a1.78 1.78 0 012.25-1.13l9.11 2.89a2.26 2.26 0 011.43 1.35l7.42 19.67a.76.76 0 01-1 1l-22.63-7.16a1.78 1.78 0 01-1.19-2.21z"
              />
              <path
                className="heat_slide_1__cls-4"
                d="M832.98 617.69l-30.88-10.62-1.68 16.93 27.78 8.79 4.78-15.1z"
              />
              <g clipPath="url(#heat_slide_1__clip-path-2)">
                <ellipse
                  className="heat_slide_1__cls-4"
                  cx={814.13}
                  cy={626.76}
                  rx={13.26}
                  ry={13.7}
                  transform="rotate(-72.44 814.095 626.755)"
                />
              </g>
              <g id="heat_slide_1__hjul">
                <ellipse
                  className="heat_slide_1__cls-4"
                  cx={814.16}
                  cy={626.68}
                  rx={11.64}
                  ry={12.03}
                  transform="rotate(-72.44 814.125 626.672)"
                />
                <ellipse
                  className="heat_slide_1__cls-4"
                  cx={814.13}
                  cy={626.76}
                  rx={7.16}
                  ry={7.4}
                  transform="rotate(-72.44 814.095 626.755)"
                />
              </g>
              <g>
                <path
                  className="heat_slide_1__cls-4"
                  d="M753.96 592.68l-30.87-10.62-1.69 16.93 27.78 8.79 4.78-15.1z"
                />
                <g clipPath="url(#heat_slide_1__clip-path-3)">
                  <ellipse
                    className="heat_slide_1__cls-4"
                    cx={735.11}
                    cy={601.75}
                    rx={13.26}
                    ry={13.7}
                    transform="rotate(-72.44 735.081 601.755)"
                  />
                </g>
                <g id="heat_slide_1__hjul-2" data-name="hjul">
                  <ellipse
                    className="heat_slide_1__cls-4"
                    cx={735.14}
                    cy={601.67}
                    rx={11.64}
                    ry={12.03}
                    transform="rotate(-72.44 735.104 601.666)"
                  />
                  <ellipse
                    className="heat_slide_1__cls-4"
                    cx={735.11}
                    cy={601.75}
                    rx={7.16}
                    ry={7.4}
                    transform="rotate(-72.44 735.081 601.755)"
                  />
                </g>
              </g>
              <rect
                className="heat_slide_1__cls-4"
                x={828.99}
                y={607.97}
                width={11.67}
                height={3.56}
                rx={1.78}
                transform="rotate(-72.44 834.791 609.755)"
              />
            </g>
            <g
              filter="url(#heat-shadow-slide-1)"
              id="heat_slide_1__Cityline"
            >
              <g id="heat_slide_1__buildings-2" data-name="buildings">
                <path
                  className="heat_slide_1__cls-4"
                  d="M522.45 480.03h71.06v15.94h-71.06zM295.45 461.73h-66.17a3.22 3.22 0 00-3.21 3.22v127.14a3.22 3.22 0 003.21 3.22h66.17a3.22 3.22 0 003.21-3.22V465a3.22 3.22 0 00-3.21-3.27zM288.71 495a3 3 0 01-3 3h-4.23a3 3 0 01-3-3v-15.58a3 3 0 013-3h4.23a3 3 0 013 3z"
                />
                <g id="heat_slide_1__sky_1-2" data-name="sky 1">
                  <path
                    className="heat_slide_1__cls-4"
                    d="M373.13 560.92H320a4.62 4.62 0 01-4.63-4.62V326.45a4.63 4.63 0 01.36-1.8l22.62-53.9a4.62 4.62 0 014.27-2.84h30.56a4.62 4.62 0 014.62 4.63V556.3a4.62 4.62 0 01-4.67 4.62z"
                  />
                  <g
                    clipPath="url(#heat_slide_1__clip-path-4)"
                  >
                    <g clipPath="url(#heat_slide_1__clip-path-6)">
                      <path
                        className="heat_slide_1__cls-13"
                        d="M298.93 288.3h82.72v13.59h-82.72z"
                        id="heat_slide_1___Grid_Repeat_"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_1__cls-13"
                        d="M298.93 318.09h82.72v13.59h-82.72z"
                        id="heat_slide_1___Grid_Repeat_2"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_1__cls-13"
                        d="M298.93 347.88h82.72v13.59h-82.72z"
                        id="heat_slide_1___Grid_Repeat_3"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_1__cls-13"
                        d="M298.93 377.67h82.72v13.59h-82.72z"
                        id="heat_slide_1___Grid_Repeat_4"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_1__cls-13"
                        d="M298.93 407.45h82.72v13.59h-82.72z"
                        id="heat_slide_1___Grid_Repeat_5"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_1__cls-13"
                        d="M298.93 437.24h82.72v13.59h-82.72z"
                        id="heat_slide_1___Grid_Repeat_6"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_1__cls-13"
                        d="M298.93 467.03h82.72v13.59h-82.72z"
                        id="heat_slide_1___Grid_Repeat_7"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                      <path
                        className="heat_slide_1__cls-13"
                        d="M298.93 496.82h82.72v13.59h-82.72z"
                        id="heat_slide_1___Grid_Repeat_8"
                        data-name="&lt;Grid Repeat&gt;"
                      />
                    </g>
                  </g>
                  <rect
                    className="heat_slide_1__cls-4"
                    x={349.95}
                    y={209.76}
                    width={4.68}
                    height={75.52}
                    rx={2.34}
                  />
                  <rect
                    className="heat_slide_1__cls-4"
                    x={344.92}
                    y={258.98}
                    width={28.22}
                    height={16.4}
                    rx={3.05}
                  />
                  <rect
                    className="heat_slide_1__cls-4"
                    x={359.8}
                    y={242.99}
                    width={6.24}
                    height={25.68}
                    rx={2.34}
                  />
                </g>
                <path
                  className="heat_slide_1__cls-4"
                  d="M535.27 593.79l-77.3-.23V327.43c0-3.28 3.17-5.95 7.09-5.95h9.08v-64.73a3.11 3.11 0 013.11-3.11h3.8V210a2.57 2.57 0 012.57-2.57 2.57 2.57 0 012.56 2.57v43.63h1.21l6.68 5.52v-23.73a2.56 2.56 0 012.56-2.56 2.56 2.56 0 012.56 2.56v28l26.86 22.23v35.85h3.82a5.4 5.4 0 015.4 5.39z"
                />
                <g opacity={0.4}>
                  <path
                    d="M468.65 544.68a1.53 1.53 0 001.53-1.55V332.39a1.54 1.54 0 00-1.53-1.56 1.54 1.54 0 00-1.52 1.56v210.74a1.53 1.53 0 001.52 1.55z"
                    fill="url(#heat_slide_1__linear-gradient)"
                  />
                  <path
                    d="M480.25 544.68a1.54 1.54 0 001.53-1.55V332.39a1.55 1.55 0 00-1.53-1.56 1.54 1.54 0 00-1.53 1.56v210.74a1.53 1.53 0 001.53 1.55z"
                    fill="url(#heat_slide_1__linear-gradient-2)"
                  />
                  <path
                    d="M491.84 544.68a1.53 1.53 0 001.53-1.55V332.39a1.54 1.54 0 00-1.53-1.56 1.55 1.55 0 00-1.53 1.56v210.74a1.54 1.54 0 001.53 1.55z"
                    fill="url(#heat_slide_1__linear-gradient-3)"
                  />
                  <path
                    d="M503.44 544.68a1.54 1.54 0 001.53-1.55V332.39a1.55 1.55 0 00-1.53-1.56 1.54 1.54 0 00-1.53 1.56v210.74a1.53 1.53 0 001.53 1.55z"
                    fill="url(#heat_slide_1__linear-gradient-4)"
                  />
                  <path
                    d="M515 544.68a1.53 1.53 0 001.53-1.55V332.39a1.54 1.54 0 00-1.53-1.56 1.55 1.55 0 00-1.53 1.56v210.74a1.54 1.54 0 001.53 1.55z"
                    fill="url(#heat_slide_1__linear-gradient-5)"
                  />
                  <path
                    d="M525.1 332.39v210.74a1.53 1.53 0 001.53 1.55 1.53 1.53 0 001.52-1.55V332.39a1.54 1.54 0 00-1.52-1.56 1.54 1.54 0 00-1.53 1.56z"
                    fill="url(#heat_slide_1__linear-gradient-6)"
                  />
                </g>
                <g id="heat_slide_1__chinese_house" data-name="chinese house">
                  <path
                    className="heat_slide_1__cls-4"
                    d="M577.24 449.83v182.56h72.59V449.83zm56.64 9.64a2.22 2.22 0 012.22-2.22h2.73a2.23 2.23 0 012.22 2.22v17a2.22 2.22 0 01-2.22 2.22h-2.73a2.21 2.21 0 01-2.22-2.22zm-36.67 57a2.23 2.23 0 012.22-2.22h2.73a2.23 2.23 0 012.22 2.22v17a2.22 2.22 0 01-2.22 2.22h-2.73a2.22 2.22 0 01-2.22-2.22z"
                  />
                  <rect
                    className="heat_slide_1__cls-4"
                    x={571.64}
                    y={443.12}
                    width={83.76}
                    height={8.32}
                    rx={1.85}
                  />
                  <path
                    className="heat_slide_1__cls-4"
                    d="M564.75 437.46L580.42 426l8.33-13.47a1.86 1.86 0 011.58-.88h47.18a1.84 1.84 0 011.57.89l8.17 13.34 15.67 11.64a1.85 1.85 0 01-1.1 3.33h-96a1.85 1.85 0 01-1.1-3.34z"
                  />
                  <rect
                    className="heat_slide_1__cls-4"
                    x={558.32}
                    y={435.6}
                    width={110.39}
                    height={11.91}
                    rx={1.85}
                  />
                  <rect
                    className="heat_slide_1__cls-4"
                    x={584.22}
                    y={407.38}
                    width={60.16}
                    height={7}
                    rx={1.85}
                  />
                </g>
                <rect
                  className="heat_slide_1__cls-4"
                  x={255.36}
                  y={447.62}
                  width={32.37}
                  height={17.56}
                  rx={2.17}
                />
                <rect
                  className="heat_slide_1__cls-4"
                  x={222.24}
                  y={456.39}
                  width={81.78}
                  height={10.77}
                  rx={5.39}
                />
                <path
                  className="heat_slide_1__cls-4"
                  d="M295.05 495.97h20.27v9.79h-20.27z"
                />
              </g>
              <g id="heat_slide_1__ground">
                <path
                  className="heat_slide_1__cls-4"
                  d="M1255.85 579.59v-2s.86.57 0 2z"
                />
                <path
                  className="heat_slide_1__cls-4"
                  d="M465.14 530.59C635.47 541.12 820.48 698 1047.59 644.15c178.65-42.36 418.52-164.44 421.68-169.72l-7.15 328.9-1473.52 3.76-18-182s324.21-105.02 494.54-94.5z"
                />
              </g>
            </g>
            <g
              filter="url(#heat-shadow-slide-1)"
              id="heat_slide_1__GREEN_FG"
              data-name="GREEN FG"
            >
              <path
                className="heat_slide_1__cls-21"
                d="M1315.91 608.02h8.63v90.95h-8.63z"
              />
              <path
                className="heat_slide_1__cls-21"
                d="M1340.9 615.13v-8h5.68v8c0 17.11-14.5 25.76-29.17 25.94v-5c11.83-.15 23.49-7.14 23.49-20.94zM1296.48 603.64v-8.58h-5.06v8.58c0 18.24 12.93 27.46 26 27.66V626c-10.55-.19-20.94-7.65-20.94-22.36z"
              />
              <path
                className="heat_slide_1__cls-21"
                d="M1277.67 556.29h4.89V556a15.61 15.61 0 0115.6-15.6h35.5a15.6 15.6 0 0115.6 15.6v.31h13.11a14.34 14.34 0 0114.33 14.34V595a14.34 14.34 0 01-14.33 14.33h-84.7a14.34 14.34 0 01-14.34-14.33v-24.37a14.34 14.34 0 0114.34-14.34zM1460.78 716.43h-66V646a33 33 0 0133-33 33 33 0 0133 33z"
              />
              <path
                className="heat_slide_1__cls-21"
                d="M1427.8 738.77h-66v-70.48a33 33 0 0133-33 33 33 0 0133 33zM1238.24 682.45a2.47 2.47 0 013 .7.78.78 0 01.1.15l7.14 14 5.65-27.2a2.51 2.51 0 014.79-.31.83.83 0 010 .15l5.11 25.67 8.3-13.56a.87.87 0 01.2-.23 2.52 2.52 0 014.09 2.15l-1.76 23.14a.88.88 0 01-.77.81l-30.88 4a.89.89 0 01-1-.7l-5.46-26a2.47 2.47 0 011.49-2.77z"
              />
              <g id="heat_slide_1__FG_2" data-name="FG 2">
                <path
                  className="heat_slide_1__cls-21"
                  d="M-43 643.58s240.21-89.18 413.18-86.48S621.46 657.27 865.59 712c48 10.75 154.51 41.2 268.1 25.95 70.87-9.5 133.36-51 196.31-69.34 96.75-28.21 175-21.89 175-21.89l-4 170H-20.45z"
                />
              </g>
            </g>
            <g
              id="heat_slide_1__FG_car"
              data-name="FG car"
            >
              <g
                filter="url(#heat-shadow-slide-1)"
              >
                <path
                  className="heat_slide_1__cls-2"
                  d="M98.07 691.83v58h306.64c-34.34-15.7-75.55-33.15-114.08-45.88-65.13-21.56-151.13-16.43-192.56-12.12z"
                />
                <path
                  className="heat_slide_1__cls-2"
                  d="M-17.06 703.31V821.6h536.91s-43.59-22.33-102.63-48.48c-45.06-20-99.13-42.13-149.67-58.31C182.09 687.46 69.27 694 14.91 699.45c-19.91 2-31.97 3.86-31.97 3.86zM107.24 581.08h7.91v83.34h-7.91z"
                />
                <path
                  className="heat_slide_1__cls-2"
                  d="M130.14 587.6v-7.37h5.21v7.37c0 15.68-13.29 23.6-26.73 23.77v-4.55c10.84-.17 21.52-6.58 21.52-19.22zM89.44 577.07v-7.87H84.8v7.87c0 16.71 11.85 25.16 23.82 25.35v-4.86c-9.62-.18-19.18-7.01-19.18-20.49z"
                />
                <path
                  className="heat_slide_1__cls-2"
                  d="M72.2 533.68h4.48v-.28A14.3 14.3 0 0191 519.1h32.53a14.3 14.3 0 0114.3 14.3v.28h12A13.14 13.14 0 01163 546.82v22.34a13.13 13.13 0 01-13.14 13.13H72.2a13.13 13.13 0 01-13.14-13.13v-22.34a13.14 13.14 0 0113.14-13.14zM168.8 627.27a2.33 2.33 0 012.75 1 1 1 0 01.07.15l4.95 13.91 8.57-24.66a2.37 2.37 0 014.51.3.78.78 0 010 .14l1.63 24.54 9.39-11.62a.76.76 0 01.21-.19 2.36 2.36 0 013.55 2.5L200 654.71a.84.84 0 01-.82.67h-29.27a.84.84 0 01-.83-.77l-1.91-24.9a2.33 2.33 0 011.63-2.44z"
                />
                <path
                  d="M-24.18 650.73l7.12 55.27c52.66-5.47 209.71-18.53 292.49 8.82 49 16.18 200.76 86.83 244.42 106.79l112.83-4.8S419.53 703.7 306.41 666.33s-330.59-15.6-330.59-15.6z"
                  fill="#734366"
                />
              </g>
              <g
                id="heat_slide_1__small_car-2"
                data-name="small car"
                transform="rotate(-12 -2950, 1607.78)"
              >

                <animateMotion
                  dur="30s"
                  rotate="auto"
                  repeatCount="indefinite"
                  keyPoints="1;0"
                  keyTimes="0;1"
                  calcMode="linear"
                  path="M-103.76,664.22c38.46-4,177.68-15.72,242.38-13.64,33.42,1.08,81,.85,129.75,11.91,80.57,18.29,165.63,59.77,240.74,97.63,55.37,27.92,101.21,51.81,203.7,86.56"
                />
                <path
                  className="heat_slide_1__cls-2"
                  d="M254.75 632l-1 3.32a6.25 6.25 0 004.2 7.77L361.5 674a6.23 6.23 0 007.77-4.2l1-3.32a6.24 6.24 0 01-4.25 4.21 6.24 6.24 0 004.25-4.21l7.37-24.76 1.43-25.82a4.93 4.93 0 00-3.57-5L331 597.65a5.08 5.08 0 00-4.32.68l-1 .69-22.93 16-1.58 1.1-2.77 1.94-20.46-6.06a16.51 16.51 0 00-20.53 11.11l-2.66 8.89a6.23 6.23 0 001.37 6 6.23 6.23 0 01-1.37-6zm82.2-6.5a3.15 3.15 0 01-3.93 2.12l-27-8 23.74-17.13 9.84 2.93a3.17 3.17 0 012.13 3.94zm32.41 12.67l-24.2-7.17a2.52 2.52 0 01-1.7-3.14l5-16.87a2.54 2.54 0 013.15-1.71l20.39 6.05a2.36 2.36 0 011.63 2.45L372.39 636a2.43 2.43 0 01-3.03 2.2z"
                />
                <path
                  className="heat_slide_1__cls-2"
                  d="M267.04 624.8l40.09 10.86-10.31 19.02-35.53-10.57 5.75-19.31z"
                />
                <g clipPath="url(#heat_slide_1__clip-path-8)">
                  <ellipse
                    className="heat_slide_1__cls-2"
                    cx={280.41}
                    cy={647.8}
                    rx={16.87}
                    ry={17.43}
                    transform="rotate(-73.42 280.373 647.78)"
                  />
                </g>
                <g id="heat_slide_1__hjul-3" data-name="hjul">
                  <ellipse
                    className="heat_slide_1__cls-2"
                    cx={280.44}
                    cy={647.69}
                    rx={14.8}
                    ry={15.3}
                    transform="rotate(-73.42 280.405 647.669)"
                  />
                  <ellipse
                    className="heat_slide_1__cls-2"
                    cx={280.41}
                    cy={647.8}
                    rx={9.11}
                    ry={9.41}
                    transform="rotate(-73.42 280.373 647.78)"
                  />
                </g>
                <path
                  className="heat_slide_1__cls-2"
                  d="M330.1 643.58l40.08 10.86-10.31 19.02-35.52-10.57 5.75-19.31z"
                />
                <g clipPath="url(#heat_slide_1__clip-path-9)">
                  <ellipse
                    className="heat_slide_1__cls-2"
                    cx={343.47}
                    cy={666.58}
                    rx={16.87}
                    ry={17.43}
                    transform="rotate(-73.42 343.424 666.56)"
                  />
                </g>
                <g id="heat_slide_1__hjul-4" data-name="hjul">
                  <ellipse
                    className="heat_slide_1__cls-2"
                    cx={343.5}
                    cy={666.47}
                    rx={14.8}
                    ry={15.3}
                    transform="rotate(-73.42 343.455 666.448)"
                  />
                  <ellipse
                    className="heat_slide_1__cls-2"
                    cx={343.47}
                    cy={666.58}
                    rx={9.11}
                    ry={9.41}
                    transform="rotate(-73.42 343.424 666.56)"
                  />
                </g>
                <path
                  className="heat_slide_1__cls-2"
                  d="M280.36 617.51l-8.23-2.45a8.13 8.13 0 00-10.13 5.47l13.89 4.14a2.22 2.22 0 002.76-1.5zM297.97 619.61l2.3.69 1.64-1.18 26.67-19.24 1.44-1.04-.84-.82-2.05 1.42-27.47 19.01-1.69 1.16z"
                />
              </g>
              <g id="heat_slide_1__strips" opacity={0.3}>
                <path
                  className="heat_slide_1__cls-26"
                  d="M34.57 683c-7.39.52-14.77 1.08-22.13 1.73A4.18 4.18 0 017.83 681l-.15-1.57a4.17 4.17 0 013.91-4.43q11.16-1 22.3-1.75a4.18 4.18 0 014.55 3.75l.11 1.58a4.18 4.18 0 01-3.98 4.42zM118.29 679.37c-7.4.1-14.8.28-22.2.5a4.18 4.18 0 01-4.4-3.93v-1.58a4.17 4.17 0 014.15-4.2q11.17-.35 22.35-.51a4.15 4.15 0 014.34 4v1.58a4.19 4.19 0 01-4.24 4.14zM201.92 681.21c-3.68-.25-7.36-.5-11-.66s-7.37-.4-11.06-.52a4.16 4.16 0 01-4.11-4.22l.06-1.58a4.17 4.17 0 014.43-3.91c3.73.12 7.46.34 11.19.52s7.46.42 11.19.67a4.15 4.15 0 014 4.35l-.11 1.57a4.21 4.21 0 01-4.59 3.78zM284.05 693.21l-5.3-1.33c-1.77-.43-3.52-.91-5.32-1.26l-5.36-1.13c-1.79-.39-3.58-.77-5.39-1.07a4.17 4.17 0 01-3.45-4.75l.27-1.55a4.19 4.19 0 015-3.25c1.86.3 3.7.7 5.55 1.09l5.53 1.17c1.85.36 3.68.86 5.52 1.31l5.5 1.38a4.19 4.19 0 013.07 5.12l-.44 1.51a4.18 4.18 0 01-5.18 2.76zM361.45 723.88L341.08 715a4.19 4.19 0 01-2.32-5.42l.62-1.45a4.16 4.16 0 015.54-2.06l20.47 8.93a4.18 4.18 0 012.25 5.47l-.64 1.44a4.16 4.16 0 01-5.55 1.97zM437.73 759l-20.15-9.46a4.18 4.18 0 01-2.15-5.5l.66-1.43a4.18 4.18 0 015.6-1.88l20.19 9.48a4.18 4.18 0 012.13 5.51l-.68 1.43a4.17 4.17 0 01-5.6 1.85zM513.4 795.44l-20-9.77a4.18 4.18 0 01-2.06-5.53l.69-1.42a4.17 4.17 0 015.62-1.79l20 9.79a4.16 4.16 0 012 5.53l-.69 1.42a4.17 4.17 0 01-5.56 1.77z"
                />
              </g>
            </g>
            <g id="heat_slide_1__FLOAT_OBJECTS" data-name="FLOAT OBJECTS">
              <g id="heat_slide_1__wind">
                <motion.g
                  animate={{
                    rotate: ['0deg', '360deg'],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 3, repeat: Infinity,
                  }}
                  style={{
                    transformBox: 'fill-box',
                    originX: 'center',
                    originY: 'center',
                  }}
                >
                  <path
                    className="heat_slide_1__cls-27"
                    d="M423.63 448.18c1.61 6.18-1.46 10.94-3.52 13.44a.5.5 0 01-.89-.33c0-2.61-.11-5.91-1.65-8.13a7.2 7.2 0 00-8.21-2.37 7 7 0 00-4.31 6.3 8.9 8.9 0 002 5.37c4.64 6.06 12.75 8 19.2 3.52 9.94-6.81 6.95-24.35 1-32.94a.41.41 0 01.6-.56c3.9 3.34 8.21 6.64 10.5 11.37a29.72 29.72 0 012 18.34c-1.52 7.54-6.31 16.35-14 18.82a27.31 27.31 0 01-18.78-1.14 25.8 25.8 0 01-12.74-12.77c-2.93-6.18-4-14.08-.7-20.3 6.13-11.45 24.1-13.81 29.12 0a14 14 0 01.44 1.4"
                  />
                </motion.g>
                <motion.g
                  animate={{
                    rotate: ['0deg', '360deg'],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    delay: 2, duration: 3, repeat: Infinity,
                  }}
                  style={{
                    transformBox: 'fill-box',
                    originX: 'center',
                    originY: 'center',
                  }}
                >
                  <path
                    id="heat_slide_1__wind-2"
                    data-name="wind"
                    className="heat_slide_1__cls-27"
                    d="M419.85 391.36c-5.2-3.7-5.91-9.32-5.94-12.56a.51.51 0 01.9-.32c1.63 2 3.87 4.46 6.48 5.17a7.22 7.22 0 007.82-3.46 7 7 0 00-.75-7.59 9 9 0 00-5-2.84c-7.45-1.65-14.88 2.11-17 9.65-3.23 11.6 10.33 23.12 20.44 25.86a.41.41 0 01-.11.81c-5.14-.06-10.56.18-15.35-2a29.71 29.71 0 01-13.33-12.74c-3.68-6.76-5.68-16.59-1.34-23.46a27.33 27.33 0 0115.13-11.19 25.81 25.81 0 0118 1.6c6.21 2.85 12.14 8.2 13.58 15.1 2.65 12.71-9.6 26.07-22.31 18.73-.43-.25-.84-.51-1.23-.79"
                  />
                </motion.g>
              </g>
              <g
                transform="translate(-1000, -150)"
              >
                <animateMotion
                  dur="10s"
                  repeatCount="indefinite"
                  keyPoints="1;0"
                  keyTimes="0;1"
                  calcMode="linear"
                  path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
                />

                <motion.g
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                >
                  <rect
                    id="heat_slide_1__wing"
                    className="heat_slide_1__cls-28"
                    x={662.42}
                    y={302.71}
                    width={18.53}
                    height={6.6}
                    rx={3.3}
                    transform="rotate(-73.7 669.717 306.433)"
                  />
                </motion.g>
                <motion.g
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                >
                  <rect
                    id="heat_slide_1__wing-2"
                    data-name="wing"
                    className="heat_slide_1__cls-29"
                    x={663.64}
                    y={297.43}
                    width={6.6}
                    height={18.53}
                    rx={3.3}
                    transform="rotate(-30 666.938 306.706)"
                  />
                </motion.g>
                <rect
                  className="heat_slide_1__cls-28"
                  x={664.46}
                  y={311.47}
                  width={12.8}
                  height={4.54}
                  rx={2.27}
                  transform="rotate(-5.26 670.51 313.645)"
                />
              </g>
              <g
                transform="translate(-1000, -150)"
              >
                <animateMotion
                  dur="20s"
                  repeatCount="indefinite"
                  keyPoints="1;0"
                  keyTimes="0;1"
                  calcMode="linear"
                  path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
                />
                <motion.g
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                >
                  <rect
                    id="heat_slide_1__wing-3"
                    data-name="wing"
                    className="heat_slide_1__cls-28"
                    x={692.04}
                    y={258.44}
                    width={18.53}
                    height={6.6}
                    rx={3.3}
                    transform="rotate(-73.55 701.332 261.733)"
                  />
                </motion.g>
                <motion.g
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                >
                  <rect
                    id="heat_slide_1__wing-4"
                    data-name="wing"
                    className="heat_slide_1__cls-29"
                    x={693.27}
                    y={253.16}
                    width={6.6}
                    height={18.53}
                    rx={3.3}
                    transform="rotate(-30 696.564 262.424)"
                  />
                </motion.g>
                <rect
                  className="heat_slide_1__cls-28"
                  x={694.09}
                  y={267.2}
                  width={12.8}
                  height={4.54}
                  rx={2.27}
                  transform="rotate(-5.26 700.139 269.41)"
                />
              </g>
              {/* ---------------birds -------------------- */}
              <motion.g
                id="heat_slide_1__bird-2"
                data-name="bird"
                animate={{
                  x: [-140, 150],
                  y: [100, -300],
                  scale: [0.7, 0.7, 1],
                  opacity: [0, 1, 1],
                }}
                transition={{
                  delay: 15, duration: 30, repeat: Infinity,
                }}
              >
                <motion.path
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                  id="heat_slide_1__wing-5"
                  data-name="wing"
                  className="heat_slide_1__cls-4"
                  d="M747.86 165.83c3.41-7.77 9.81-14 17.13-16.79a.63.63 0 01.86.57l1.23 33.68a.66.66 0 01-.2.48.58.58 0 01-.48.18l-20.21-1.24a.57.57 0 01-.18 0 .65.65 0 01-.42-.53l-.14-1.16a29.07 29.07 0 012.41-15.19z"
                />
                <path
                  className="heat_slide_1__cls-30"
                  d="M755 171.17l17-4.17a.62.62 0 01.51.1.61.61 0 01.27.44l1.47 11.89c0 .22.05.45.06.66a12.58 12.58 0 01-9.88 12.85l-27.06 5.63a.69.69 0 01-.35 0 .55.55 0 01-.15-.08.66.66 0 01-.27-.43 23.27 23.27 0 01-.09-5.9A24.9 24.9 0 01755 171.17z"
                />
                <path
                  className="heat_slide_1__cls-31"
                  d="M754.91 183.7l18.64-4.19a.66.66 0 01.53.11.65.65 0 01.25.47 12.58 12.58 0 01-9.88 12.85L747 196.56a.78.78 0 01-.34 0 .51.51 0 01-.16-.09.61.61 0 01-.26-.43 11.28 11.28 0 018.67-12.34z"
                />
                <motion.path
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                  id="heat_slide_1__wing-6"
                  data-name="wing"
                  className="heat_slide_1__cls-30"
                  d="M751.2 149a.65.65 0 01.56-.11.69.69 0 01.43.39l11.55 30a.63.63 0 010 .52.68.68 0 01-.41.33l-19.93 5.35a.62.62 0 01-.38 0 .65.65 0 01-.36-.33l-.49-1a26.65 26.65 0 01-2.24-14.58A31 31 0 01751.2 149z"
                />
                <g
                  id="heat_slide_1__general_eye_shape-2"
                  data-name="general eye shape"
                >
                  <g id="heat_slide_1__EYE-2" data-name="EYE">
                    <path
                      id="heat_slide_1__eyes-2"
                      data-name="eyes"
                      className="heat_slide_1__cls-3"
                      d="M771.06 172.77a4.27 4.27 0 01-4.26 3.64 3.32 3.32 0 01-3.15-3.91 4.29 4.29 0 014.23-3.68 3.32 3.32 0 013.18 3.95z"
                    />
                    <g clipPath="url(#heat_slide_1__clip-path-10)">
                      <path
                        id="heat_slide_1__eyes-3"
                        data-name="eyes"
                        d="M773.43 171.77a4.33 4.33 0 01-4.3 3.67 3.32 3.32 0 01-3.16-3.93 4.32 4.32 0 014.26-3.7 3.32 3.32 0 013.2 3.96z"
                      />
                    </g>
                  </g>
                </g>
                <path
                  id="heat_slide_1__n\xE6b"
                  className="heat_slide_1__cls-31"
                  d="M779.41 170.69l-6.06 1.43-.35-2.85 6.06-1.47a1.19 1.19 0 011.52 1v.24a1.59 1.59 0 01-1.17 1.65z"
                />
              </motion.g>
              <motion.g
                id="heat_slide_1__bird-2"
                data-name="bird"
                animate={{
                  x: [-140, 150],
                  y: [100, -300],
                  scale: [0.7, 1],
                  opacity: [0, 1, 1],
                }}
                transition={{
                  duration: 30, repeat: Infinity,
                }}
              >
                <motion.path
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                  id="heat_slide_1__wing-5"
                  data-name="wing"
                  className="heat_slide_1__cls-4"
                  d="M747.86 165.83c3.41-7.77 9.81-14 17.13-16.79a.63.63 0 01.86.57l1.23 33.68a.66.66 0 01-.2.48.58.58 0 01-.48.18l-20.21-1.24a.57.57 0 01-.18 0 .65.65 0 01-.42-.53l-.14-1.16a29.07 29.07 0 012.41-15.19z"
                />
                <path
                  className="heat_slide_1__cls-30"
                  d="M755 171.17l17-4.17a.62.62 0 01.51.1.61.61 0 01.27.44l1.47 11.89c0 .22.05.45.06.66a12.58 12.58 0 01-9.88 12.85l-27.06 5.63a.69.69 0 01-.35 0 .55.55 0 01-.15-.08.66.66 0 01-.27-.43 23.27 23.27 0 01-.09-5.9A24.9 24.9 0 01755 171.17z"
                />
                <path
                  className="heat_slide_1__cls-31"
                  d="M754.91 183.7l18.64-4.19a.66.66 0 01.53.11.65.65 0 01.25.47 12.58 12.58 0 01-9.88 12.85L747 196.56a.78.78 0 01-.34 0 .51.51 0 01-.16-.09.61.61 0 01-.26-.43 11.28 11.28 0 018.67-12.34z"
                />
                <motion.path
                  animate={{
                    scaleY: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'center',
                    transformBox: 'fill-box',
                  }}
                  id="heat_slide_1__wing-6"
                  data-name="wing"
                  className="heat_slide_1__cls-30"
                  d="M751.2 149a.65.65 0 01.56-.11.69.69 0 01.43.39l11.55 30a.63.63 0 010 .52.68.68 0 01-.41.33l-19.93 5.35a.62.62 0 01-.38 0 .65.65 0 01-.36-.33l-.49-1a26.65 26.65 0 01-2.24-14.58A31 31 0 01751.2 149z"
                />
                <g
                  id="heat_slide_1__general_eye_shape-2"
                  data-name="general eye shape"
                >
                  <g id="heat_slide_1__EYE-2" data-name="EYE">
                    <path
                      id="heat_slide_1__eyes-2"
                      data-name="eyes"
                      className="heat_slide_1__cls-3"
                      d="M771.06 172.77a4.27 4.27 0 01-4.26 3.64 3.32 3.32 0 01-3.15-3.91 4.29 4.29 0 014.23-3.68 3.32 3.32 0 013.18 3.95z"
                    />
                    <g clipPath="url(#heat_slide_1__clip-path-10)">
                      <path
                        id="heat_slide_1__eyes-3"
                        data-name="eyes"
                        d="M773.43 171.77a4.33 4.33 0 01-4.3 3.67 3.32 3.32 0 01-3.16-3.93 4.32 4.32 0 014.26-3.7 3.32 3.32 0 013.2 3.96z"
                      />
                    </g>
                  </g>
                </g>
                <path
                  id="heat_slide_1__n\xE6b"
                  className="heat_slide_1__cls-31"
                  d="M779.41 170.69l-6.06 1.43-.35-2.85 6.06-1.47a1.19 1.19 0 011.52 1v.24a1.59 1.59 0 01-1.17 1.65z"
                />
              </motion.g>
              <path
                id="heat_slide_1__cloud"
                className="heat_slide_1__cls-27"
                d="M173.69 340.47a19.37 19.37 0 0117.64-19.31 19.38 19.38 0 0118.74-14.46h2.55a19.36 19.36 0 0118.71 14.39h5.55a19.4 19.4 0 0119.38 19.38v2a18.86 18.86 0 01-1 6 3 3 0 01-2.88 2.07h-74.9a3 3 0 01-2.88-2.07 19.14 19.14 0 01-.95-6v-1c0-.32.04-.66.04-1z"
              />
              <path
                id="heat_slide_1__cloud-2"
                data-name="cloud"
                className="heat_slide_1__cls-27"
                d="M564.63 188.25A19.37 19.37 0 01582.27 169 19.38 19.38 0 01601 154.48h2.55a19.37 19.37 0 0118.71 14.39h5.56a19.39 19.39 0 0119.37 19.38v2a18.86 18.86 0 01-1 6 3 3 0 01-2.88 2.07h-74.9a3 3 0 01-2.87-2.07 18.86 18.86 0 01-1-6v-1c0-.33.09-.66.09-1z"
              />
              <path
                id="heat_slide_1__cloud-3"
                data-name="cloud"
                className="heat_slide_1__cls-27"
                d="M698.93 458.24a17.6 17.6 0 0116-17.51 17.58 17.58 0 0117-13.12h2.31a17.58 17.58 0 0117 13.06h5a17.6 17.6 0 0117.58 17.57v1.84a17.37 17.37 0 01-.87 5.45 2.75 2.75 0 01-2.62 1.88h-67.92a2.74 2.74 0 01-2.61-1.88 17.09 17.09 0 01-.87-5.45v-.92-.92z"
              />
              <g id="heat_slide_1__heat_line" data-name="heat line">
                <motion.path
                  animate={{
                    pathLength: [0, 1],
                    opacity: [0, 1, 0],
                  }}
                  transition={{ duration: 2, repeat: Infinity }}
                  className="heat_slide_1__cls-33"
                  d="M416.13 647.53c0-5.4 5.74-5.4 5.74-10.79s-5.74-5.4-5.74-10.8 5.74-5.4 5.74-10.79-5.74-5.4-5.74-10.8 5.74-5.4 5.74-10.8-5.74-5.4-5.74-10.8"
                />
              </g>
              <g id="heat_slide_1__heat_line-2" data-name="heat line">
                <motion.path
                  animate={{
                    pathLength: [0, 1],
                    opacity: [0, 1, 0],
                  }}
                  transition={{ delay: 0.5, duration: 2, repeat: Infinity }}
                  className="heat_slide_1__cls-33"
                  d="M532.4 687.07c0-5.39 5.74-5.39 5.74-10.79s-5.74-5.4-5.74-10.79 5.74-5.4 5.74-10.8-5.74-5.4-5.74-10.79 5.74-5.4 5.74-10.8-5.74-5.4-5.74-10.81"
                />
              </g>
              <g id="heat_slide_1__heat_line-3" data-name="heat line">
                <motion.path
                  animate={{
                    pathLength: [0, 1],
                    opacity: [0, 1, 0],
                  }}
                  transition={{ delay: 1, duration: 2, repeat: Infinity }}
                  className="heat_slide_1__cls-33"
                  d="M958.12 592.19c0-5.39 5.74-5.39 5.74-10.79s-5.74-5.4-5.74-10.79 5.74-5.4 5.74-10.8-5.74-5.4-5.74-10.79 5.74-5.4 5.74-10.81-5.74-5.4-5.74-10.8"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HeatAnimationSlide1;
