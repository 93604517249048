import * as React from 'react';

function HeatNoAnimationSlide5(props) {
  return (
    <svg
      className="no-animation"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="heat_slide_5__clip-path">
          <rect
            className="heat_slide_5__cls-1"
            x={280.31}
            y={469.29}
            width={63.61}
            height={29.02}
            rx={14.18}
            transform="rotate(-14.73 312.118 483.78)"
          />
        </clipPath>
        <clipPath id="heat_slide_5__clip-path-2">
          <path
            className="heat_slide_5__cls-1"
            d="M323.14 474.69a7.66 7.66 0 115.74 9.28 7.71 7.71 0 01-5.74-9.28"
          />
        </clipPath>
        <clipPath id="heat_slide_5__clip-path-3">
          <path
            className="heat_slide_5__cls-1"
            d="M966.88 531.69c-2 8.87-10.12 14.6-18.21 12.8s-13-10.45-11-19.32 10.13-14.59 18.21-12.79 13 10.45 11 19.31"
          />
        </clipPath>
        <clipPath id="heat_slide_5__clip-path-4">
          <path
            className="heat_slide_5__cls-1"
            d="M829.42 656.79h83.48v-1.07a46.3 46.3 0 00-46.3-46.3h-30.16a7 7 0 00-7 7z"
          />
        </clipPath>
        <clipPath id="heat_slide_5__clip-path-5">
          <path
            className="heat_slide_5__cls-1"
            d="M834.06 639.89c-3.89 6.11-11.56 8.2-17.13 4.66s-6.95-11.37-3.06-17.49 11.55-8.2 17.13-4.66 6.94 11.37 3.06 17.49"
          />
        </clipPath>
        <clipPath id="heat_slide_5__clip-path-6">
          <path
            className="heat_slide_5__cls-1"
            d="M400.39 584.25A14.56 14.56 0 01412.17 567a15.34 15.34 0 0117.33 12.48 14.57 14.57 0 01-11.77 17.28 15.35 15.35 0 01-17.34-12.49"
          />
        </clipPath>
        <style>
          {
            '.heat_slide_5__cls-1{fill:none}.heat_slide_5__cls-4{fill:#eb826a}.heat_slide_5__cls-5{fill:#fbec6d}.heat_slide_5__cls-7{fill:#414042}.heat_slide_5__cls-9{fill:#f1f2f2}.heat_slide_5__cls-10{fill:#f99c43}.heat_slide_5__cls-12{fill:#86a3ce}.heat_slide_5__cls-13{fill:#74f3de}.heat_slide_5__cls-14{fill:#71dbdb}.heat_slide_5__cls-18{fill:#60c4cc}.heat_slide_5__cls-19{fill:#796598}.heat_slide_5__cls-20{fill:#62a1d0}.heat_slide_5__cls-22{fill:#70c9eb}.heat_slide_5__cls-30{fill:#94fffa}.heat_slide_5__cls-31{fill:#c5fff4}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="heat_slide_5__Layer_1" data-name="Layer 1">
          <g id="heat_slide_5___6_Fremtidens_Byer" data-name="6 Fremtidens Byer">
            <g id="heat_slide_5__CHR">
              <g id="heat_slide_5__bee-2" data-name="bee">
                <path
                  className="heat_slide_5__cls-4"
                  d="M322.87 503.55l2.66-.65.03-7.77-2.66.66-.03 7.76zM317.82 504.79l2.66-.66.02-7.76-2.66.66-.02 7.76zM306.12 507.66l2.66-.66.02-7.76-2.65.66-.03 7.76zM301.07 508.9l2.66-.66.02-7.76-2.66.65-.02 7.77z"
                />
                <rect
                  className="heat_slide_5__cls-5"
                  x={280.31}
                  y={469.29}
                  width={63.61}
                  height={29.02}
                  rx={14.18}
                  transform="rotate(-14.73 312.118 483.78)"
                />
                <g clipPath="url(#heat_slide_5__clip-path)">
                  <path
                    className="heat_slide_5__cls-7"
                    d="M353.24 485.89a12.4 12.4 0 01-20.94 0l-1.36.86a14 14 0 0023.67 0z"
                  />
                </g>
                <g clipPath="url(#heat_slide_5__clip-path-2)">
                  <path
                    className="heat_slide_5__cls-9"
                    d="M323.14 474.69a7.66 7.66 0 115.74 9.28 7.71 7.71 0 01-5.74-9.28"
                  />
                  <path
                    className="heat_slide_5__cls-7"
                    d="M330.6 476.39a7.66 7.66 0 115.75 9.28 7.73 7.73 0 01-5.75-9.28"
                  />
                </g>
                <path
                  className="heat_slide_5__cls-10"
                  d="M302.62 501.3l10.46-2.75 9.95-2.62-7.38-28.06-10.65 2.79-9.77 2.57z"
                />
                <path
                  className="heat_slide_5__cls-4"
                  d="M265 450.28l3.2-.85a29.22 29.22 0 0135.69 20.84l.18.65-3.21.84a29.21 29.21 0 01-35.69-20.83z"
                />
                <path
                  d="M321 432.82l.85 3.21A29.23 29.23 0 01301 471.72l-.65.17-.84-3.21A29.22 29.22 0 01320.33 433z"
                  fill="#f5b1a6"
                />
              </g>
              <rect
                className="heat_slide_5__cls-12"
                x={976.28}
                y={631.42}
                width={7.17}
                height={45.02}
                rx={3.51}
              />
              <rect
                className="heat_slide_5__cls-12"
                x={941.51}
                y={650.35}
                width={45.02}
                height={7.17}
                rx={3.51}
                transform="rotate(-77.44 963.971 653.934)"
              />
              <path
                className="heat_slide_5__cls-13"
                d="M926.38 593.31l55.8 3.13 3.88-69.19a27.31 27.31 0 00-25.74-28.79l-1.26-.07a27.31 27.31 0 00-28.8 25.73z"
              />
              <path
                className="heat_slide_5__cls-14"
                d="M927.69 589l110 6.17a2.48 2.48 0 012.35 2.63l-.07 1.27a52.56 52.56 0 01-55.43 49.54l-27.45-1.54a33.65 33.65 0 01-31.71-35.48l1.17-21.59a1.09 1.09 0 011.14-1z"
              />
              <g clipPath="url(#heat_slide_5__clip-path-3)">
                <path
                  className="heat_slide_5__cls-9"
                  d="M966.88 531.69c-2 8.87-10.13 14.6-18.21 12.8s-13-10.45-11-19.32 10.13-14.59 18.21-12.79 13 10.45 11 19.31"
                />
                <path
                  className="heat_slide_5__cls-7"
                  d="M952.25 528.43c-2 8.87-10.13 14.6-18.21 12.79s-13-10.44-11-19.31 10.12-14.59 18.21-12.79 13 10.45 11 19.31"
                />
              </g>
              <path
                d="M932.68 542.19a.73.73 0 00-.69-.77l-14.84-.84a.74.74 0 00-.77.62c0 .06-.9 6.2 4.88 8.88a29.67 29.67 0 005.77 2 22 22 0 004.35.64.72.72 0 00.74-.68z"
                fill="#f8a444"
              />
              <path
                d="M932.35 529.61l-.71 12.66s-2.29 3.11-8.49 2.75a86.55 86.55 0 01-10.25-1.53s-.22-8.62 6.81-11.53a28.8 28.8 0 0112.64-2.35z"
                fill="#f9bd74"
                stroke="#f9bd74"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.37}
              />
              <path
                className="heat_slide_5__cls-18"
                d="M974.68 645.43c-22.43-2.43-18.82-28.43-18.26-29.56s3.48-18.43 23.53-20.36 54 13.17 53.66 16.64-23.5 37.1-58.93 33.28z"
              />
              <g id="heat_slide_5__cut_bug-2" data-name="cut bug">
                <path
                  className="heat_slide_5__cls-19"
                  d="M901 669.15l-7.28-10.46a2.17 2.17 0 00-3-.53 2.15 2.15 0 00-.54 3l7.29 10.46a2.16 2.16 0 001.77.92 2.13 2.13 0 001.23-.38 2.18 2.18 0 00.9-1.4 2.14 2.14 0 00-.37-1.61zM845.1 658.85a2.15 2.15 0 00-2.9 1l-5.7 11.39a2.15 2.15 0 00-.12 1.65 2.12 2.12 0 001.08 1.25 2.22 2.22 0 001 .23 2.16 2.16 0 001.94-1.2l5.7-11.39a2.16 2.16 0 00-1-2.9zM871.27 660.78a2.16 2.16 0 00-3.94 1.77l5.24 11.62a2.14 2.14 0 001.2 1.13 2.06 2.06 0 00.77.14 2.16 2.16 0 002-3.05z"
                />
                <path
                  className="heat_slide_5__cls-20"
                  d="M829.42 656.79h83.48v-1.07a46.3 46.3 0 00-46.3-46.3h-30.16a7 7 0 00-7 7z"
                />
                <g clipPath="url(#heat_slide_5__clip-path-4)">
                  <path
                    className="heat_slide_5__cls-22"
                    d="M905.64 642.75a51.37 51.37 0 00-8.28-12.27 50.51 50.51 0 00-14.76-11.06c-7.52-3.76-18-5-25.54-5.45a129.29 129.29 0 00-14.59 0l.08 1.15c.25 0 25.46-1.7 39.53 5.33a49.28 49.28 0 0122.5 22.75 46.85 46.85 0 014.35 13.73l1.15-.13a46.54 46.54 0 00-4.44-14.05z"
                  />
                  <path
                    className="heat_slide_5__cls-22"
                    d="M872.4 630.42c-11-5.38-29.12-5.83-29.88-5.84v1.15c.19 0 18.62.46 29.4 5.73a40.8 40.8 0 0117.63 16.13 32 32 0 013.76 9.38l1.15-.19a32.68 32.68 0 00-3.88-9.72 41.82 41.82 0 00-18.18-16.64zM862.49 643.67c-7.36-2.63-19.46-2.85-20-2.86v1.16c.12 0 12.42.22 19.6 2.79 6.32 2.25 9.82 5.47 11.65 7.76a13 13 0 012.48 4.49l1.13-.27a13.93 13.93 0 00-2.65-4.88c-1.92-2.43-5.6-5.86-12.21-8.19z"
                  />
                </g>
                <path
                  className="heat_slide_5__cls-18"
                  d="M827.43 635.59h15.07v21.37h-15.07z"
                />
                <path
                  className="heat_slide_5__cls-14"
                  d="M832.5 609.42A31.57 31.57 0 00802.24 632c-.56 1.89-3 8.83-12.41 12.57-7.66 3-26.26-1.15-28.65-1.15-4.81 0-4.14 4.47-1.33 6 7.83 4.14 27.66 7.45 46.3 7.45H830a12.54 12.54 0 0012.54-12.53v-34.92z"
                />
                <path
                  d="M820.64 656.79c0 2.84 6.3 5.14 23.36 5.14h48.7c15.23 0 20.2-2.3 20.2-5.14z"
                  fill="#4b83b5"
                />
                <g id="heat_slide_5__eye-2" data-name="eye">
                  <g clipPath="url(#heat_slide_5__clip-path-5)">
                    <path
                      className="heat_slide_5__cls-9"
                      d="M834.06 639.89c-3.89 6.11-11.56 8.2-17.13 4.66s-6.95-11.37-3.07-17.49 11.56-8.2 17.14-4.66 6.94 11.37 3.06 17.49"
                    />
                    <path
                      className="heat_slide_5__cls-7"
                      d="M824 633.47c-3.88 6.12-11.55 8.21-17.13 4.67s-7-11.37-3.06-17.49 11.55-8.2 17.13-4.66 7 11.37 3.06 17.48"
                    />
                  </g>
                </g>
              </g>
              <g id="heat_slide_5__bird-2" data-name="bird">
                <rect
                  className="heat_slide_5__cls-20"
                  x={385.73}
                  y={646.02}
                  width={8.18}
                  height={42.42}
                  rx={4.01}
                  transform="rotate(-1.17 389.395 666.596)"
                  id="heat_slide_5__feet"
                />
                <rect
                  className="heat_slide_5__cls-20"
                  x={401.1}
                  y={646.02}
                  width={8.18}
                  height={42.42}
                  rx={4.01}
                  transform="rotate(-1.17 404.58 666.262)"
                  id="heat_slide_5__feet-2"
                  data-name="feet"
                />
                <path
                  className="heat_slide_5__cls-18"
                  d="M419.11 605.8l-48.31 41.93c-7.1 6.16-1.67 16.5 8.19 15.58l58.5-5.45c8.67-.8 15.13-7.58 14.43-15.13l-2.58-27.68c-1.22-13.05-19.34-18.66-30.23-9.25z"
                />
                <rect
                  x={432.01}
                  y={582.29}
                  width={35.64}
                  height={11.03}
                  rx={5.4}
                  transform="rotate(-5.32 449.94 587.89)"
                  fill="#f4cb5d"
                />
                <path
                  d="M462.7 592.15l-11.47 1.07a10.08 10.08 0 01-11-9.11l-.09-.94 21.51-2a5.41 5.41 0 015.88 4.88v.23a5.4 5.4 0 01-4.83 5.87z"
                  fill="#fced6f"
                />
                <path
                  className="heat_slide_5__cls-13"
                  d="M393.75 567l-73.08 72.54c-10.74 10.66-2.21 28.91 12.86 27.5l89.47-8.29a24.1 24.1 0 0021.77-26.24l-4.52-48.61c-2.16-23-30.1-33.14-46.5-16.9z"
                />
                <path
                  className="heat_slide_5__cls-18"
                  d="M360.38 596.15l-52.76 53.19c-7.75 7.81-1.56 21.22 9.33 20.2l64.66-6a17.56 17.56 0 0015.71-19.24L394 608.58c-1.57-16.89-21.78-24.36-33.62-12.43z"
                />
                <g clipPath="url(#heat_slide_5__clip-path-6)">
                  <path
                    className="heat_slide_5__cls-9"
                    d="M400.39 584.25A14.56 14.56 0 01412.17 567a15.34 15.34 0 0117.33 12.48 14.57 14.57 0 01-11.77 17.28 15.35 15.35 0 01-17.34-12.49"
                  />
                  <path
                    className="heat_slide_5__cls-7"
                    d="M415 581.86a14.58 14.58 0 0111.78-17.28 15.35 15.35 0 0117.33 12.49 14.56 14.56 0 01-11.78 17.27A15.34 15.34 0 01415 581.86"
                  />
                </g>
                <path
                  d="M390.51 541.91a18.16 18.16 0 0123.25 6.73l19-9.12a5.7 5.7 0 114.93 10.27l-51.63 24.79-4.06-8.44a18.16 18.16 0 018.51-24.23z"
                  fill="#72cbf1"
                />
              </g>
              <g className="fly">
                <g>
                  <rect
                    className="heat_slide_5__cls-10"
                    x={874.77}
                    y={413.09}
                    width={29.99}
                    height={10.67}
                    rx={5.23}
                    transform="rotate(-73.55 889.79 418.418)"
                  />
                </g>
                <g>
                  <rect
                    className="heat_slide_5__cls-5"
                    x={876.75}
                    y={404.55}
                    width={10.67}
                    height={29.99}
                    rx={5.23}
                    transform="rotate(-30 882.086 419.534)"
                  />
                </g>
                <rect
                  className="heat_slide_5__cls-5"
                  x={878.08}
                  y={427.26}
                  width={20.7}
                  height={7.34}
                  rx={3.6}
                  transform="rotate(-5.26 887.982 430.752)"
                />
              </g>
              <g className="fly">
                <g>
                  <rect
                    className="heat_slide_5__cls-10"
                    x={946.41}
                    y={379.29}
                    width={29.99}
                    height={10.67}
                    rx={5.23}
                    transform="rotate(-73.7 958.682 385.355)"
                  />
                </g>
                <g>
                  <rect
                    className="heat_slide_5__cls-5"
                    x={948.4}
                    y={370.75}
                    width={10.67}
                    height={29.99}
                    rx={5.23}
                    transform="rotate(-30 953.743 385.735)"
                  />
                </g>
                <rect
                  className="heat_slide_5__cls-5"
                  x={949.72}
                  y={393.45}
                  width={20.7}
                  height={7.34}
                  rx={3.6}
                  transform="rotate(-5.26 959.673 396.957)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HeatNoAnimationSlide5;
