import * as React from 'react';
import { motion } from 'framer-motion';

function HeatAnimationSlide8(props) {
  return (
    <svg
      id="heat_slide_8__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="heat_slide_8__clip-path">
          <path
            id="heat_slide_8__eyes"
            className="heat_slide_8__cls-1"
            d="M782.49 212.1a4.12 4.12 0 01-4.09 3.51 3.19 3.19 0 01-3-3.76 4.11 4.11 0 014.05-3.53 3.18 3.18 0 013.04 3.78z"
          />
        </clipPath>
        <clipPath id="heat_slide_8__clip-path-2">
          <path
            id="heat_slide_8__eyes-2"
            data-name="eyes"
            className="heat_slide_8__cls-1"
            d="M849.25 151a4.11 4.11 0 01-4.09 3.5 3.19 3.19 0 01-3-3.76 4.12 4.12 0 014.05-3.53 3.19 3.19 0 013.04 3.79z"
          />
        </clipPath>
        <style>
          {
            '.heat_slide_8__cls-1{fill:#fff}.heat_slide_8__cls-2{fill:#fbebca}.heat_slide_8__cls-3{fill:#c6baff}.heat_slide_8__cls-4{fill:#eed7ff}.heat_slide_8__cls-5{fill:#46c6bd}.heat_slide_8__cls-6{fill:#74f3de}.heat_slide_8__cls-7{fill:#97ffd7}.heat_slide_8__cls-9{fill:#2e8aa3}'
          }
        </style>
      </defs>
      <g id="heat_slide_8__float_GRP" data-name="float GRP">
        <g id="heat_slide_8__clouds">
          <motion.path
            animate={{
              x: [0, 500],
              opacity: [0, 1, 0],
            }}
            transition={{
              duration: 30, repeat: Infinity,
            }}
            className="heat_slide_8__cls-2"
            d="M243.53 86.68a18.31 18.31 0 0116.64-18.27 18.29 18.29 0 0117.67-13.69h2.4a18.29 18.29 0 0117.65 13.62h5.23a18.33 18.33 0 0118.27 18.34v1.94a18 18 0 01-.9 5.69 2.85 2.85 0 01-2.72 2h-70.62a2.86 2.86 0 01-2.72-2 18 18 0 01-.9-5.69v-1-.94z"
          />
          <motion.path
            transform="translate(0, 3000)"
            animate={{
              x: [-500, 0],
              y: [400, 400],
              opacity: [0, 1, 0],
            }}
            transition={{
              delay: 5, duration: 30, repeat: Infinity,
            }}
            className="heat_slide_8__cls-2"
            d="M153.45 313.23A18.33 18.33 0 01170.09 295a18.29 18.29 0 0117.67-13.69h2.4a18.3 18.3 0 0117.65 13.63H213a18.32 18.32 0 0118.27 18.33v1.95a18 18 0 01-.9 5.69 2.87 2.87 0 01-2.72 2h-70.58a2.86 2.86 0 01-2.72-2 18 18 0 01-.9-5.69v-1-.99z"
          />
          <motion.path
            transform="translate(0, 3000)"
            animate={{
              x: [-500, 0],
              y: [400, 400],
              opacity: [0, 1, 0],
            }}
            transition={{
              delay: 3, duration: 30, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
            }}
            className="heat_slide_8__cls-2"
            d="M720.18 122.85a18.31 18.31 0 0116.64-18.27 18.28 18.28 0 0117.67-13.7h2.4a18.3 18.3 0 0117.65 13.63h5.23A18.32 18.32 0 01798 122.85v1.94a18 18 0 01-.9 5.69 2.86 2.86 0 01-2.72 2H723.8a2.87 2.87 0 01-2.72-2 18 18 0 01-.9-5.69v-1-.94z"
          />
        </g>
        <g
          className="fly"
          transform="translate(-1000, -150)"
        >
          <animateMotion
            dur="5s"
            repeatCount="indefinite"
            path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
          />
          <motion.path
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originY: 'bottom',
              originX: 'center',
            }}
            className="heat_slide_8__cls-3"
            d="M717.45 329.74l4.56-15.5a3.41 3.41 0 014.24-2.31l1.27.38a3.42 3.42 0 012.31 4.25l-4.55 15.5a3.43 3.43 0 01-4.25 2.32l-1.27-.38a3.43 3.43 0 01-2.31-4.26z"
          />
          <motion.path
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originY: 'bottom',
              originX: 'center',
            }}
            className="heat_slide_8__cls-4"
            d="M718.27 333.05l-8-14a3.42 3.42 0 011.25-4.68l1.15-.67a3.42 3.42 0 014.67 1.25l8 14a3.42 3.42 0 01-1.25 4.68l-1.15.67a3.41 3.41 0 01-4.67-1.25z"
          />
          <rect
            className="heat_slide_8__cls-3"
            x={714.71}
            y={329.93}
            width={15.83}
            height={5.63}
            rx={2.35}
            transform="rotate(-5.28 722.307 332.578)"
          />
        </g>
        <g
          className="fly"
          transform="translate(-1000, -150)"
        >
          <animateMotion
            dur="3s"
            repeatCount="indefinite"
            keyPoints="1;0"
            keyTimes="0;1"
            calcMode="linear"
            path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
          />
          <motion.path
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originY: 'bottom',
              originX: 'center',
            }}
            className="heat_slide_8__cls-3"
            d="M665.23 360.83l4.56-15.49A3.41 3.41 0 01674 343l1.27.38a3.43 3.43 0 012.31 4.26l-4.55 15.49a3.42 3.42 0 01-4.25 2.32l-1.27-.38a3.42 3.42 0 01-2.28-4.24z"
          />
          <motion.path
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originY: 'bottom',
              originX: 'center',
            }}
            className="heat_slide_8__cls-4"
            d="M666.05 364.15l-8-14a3.42 3.42 0 011.25-4.68l1.15-.67a3.42 3.42 0 014.67 1.26l8 14a3.42 3.42 0 01-1.25 4.68l-1.15.67a3.4 3.4 0 01-4.67-1.26z"
          />
          <rect
            className="heat_slide_8__cls-3"
            x={662.49}
            y={361.02}
            width={15.83}
            height={5.63}
            rx={2.35}
            transform="rotate(-5.28 670.018 363.637)"
          />
        </g>
        <g
          className="fly"
          transform="translate(-1000, -150)"
        >
          <animateMotion
            dur="10s"
            repeatCount="indefinite"
            keyPoints="1;0"
            keyTimes="0;1"
            calcMode="linear"
            path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
          />
          <motion.path
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originY: 'bottom',
              originX: 'center',
            }}
            className="heat_slide_8__cls-3"
            d="M408.12 350.54l4.56-15.5a3.41 3.41 0 014.24-2.31l1.27.37a3.43 3.43 0 012.31 4.26l-4.5 15.5a3.43 3.43 0 01-4.25 2.32l-1.27-.38a3.43 3.43 0 01-2.36-4.26z"
          />
          <motion.path
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originY: 'bottom',
              originX: 'center',
            }}
            className="heat_slide_8__cls-4"
            d="M408.94 353.85l-8.05-14a3.42 3.42 0 011.25-4.68l1.15-.67a3.41 3.41 0 014.67 1.25l8 14a3.42 3.42 0 01-1.25 4.68l-1.15.67a3.41 3.41 0 01-4.62-1.25z"
          />
          <rect
            className="heat_slide_8__cls-3"
            x={405.38}
            y={350.73}
            width={15.83}
            height={5.63}
            rx={2.35}
            transform="rotate(-5.28 413.053 353.419)"
          />
        </g>
        <g
          id="heat_slide_8__bird-3"
          data-name="bird"
          transform="translate(-1000, -150)"
        >
          <animateMotion
            dur="3s"
            repeatCount="indefinite"
            keyPoints="1;0"
            keyTimes="0;1"
            calcMode="linear"
            path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
          />
          <motion.path
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originY: 'bottom',
              originX: 'center',
            }}
            className="heat_slide_8__cls-5"
            d="M760.27 205.44c3.27-7.46 9.4-13.5 16.4-16.14a.62.62 0 01.56.07.63.63 0 01.27.48l1.17 32.37a.58.58 0 01-.19.46.57.57 0 01-.46.17l-19.35-1.19a.76.76 0 01-.16 0 .62.62 0 01-.41-.51L758 220a28 28 0 012.27-14.56z"
          />
          <path
            className="heat_slide_8__cls-6"
            d="M767.13 210.57l16.27-4a.57.57 0 01.49.09.61.61 0 01.26.43l1.41 11.42c0 .21 0 .43.06.63a12.09 12.09 0 01-9.47 12.35l-25.9 5.4a.53.53 0 01-.33 0l-.15-.07a.6.6 0 01-.25-.42 21.51 21.51 0 01-.09-5.67 23.88 23.88 0 0117.7-20.16z"
          />
          <path
            className="heat_slide_8__cls-7"
            d="M767 222.61l17.84-4a.62.62 0 01.5.1.65.65 0 01.25.45 12.09 12.09 0 01-9.47 12.35L759.49 235a.62.62 0 01-.33 0l-.15-.07a.6.6 0 01-.25-.42 10.86 10.86 0 018.24-11.9z"
          />
          <motion.path
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originY: 'bottom',
              originX: 'center',
            }}
            className="heat_slide_8__cls-6"
            d="M763.47 189.24a.61.61 0 01.54-.11.64.64 0 01.4.37l11.07 28.86a.6.6 0 010 .49.63.63 0 01-.38.32l-19.1 5.14a.56.56 0 01-.36 0 .61.61 0 01-.35-.32l-.46-1a25.7 25.7 0 01-2.15-14 29.84 29.84 0 0110.79-19.75z"
          />
          <g id="heat_slide_8__general_eye_shape-3" data-name="general eye shape">
            <g id="heat_slide_8__EYE-3" data-name="EYE">
              <path
                id="heat_slide_8__eyes-3"
                data-name="eyes"
                className="heat_slide_8__cls-1"
                d="M782.49 212.1a4.12 4.12 0 01-4.09 3.51 3.19 3.19 0 01-3-3.76 4.11 4.11 0 014.05-3.53 3.18 3.18 0 013.04 3.78z"
              />
              <g clipPath="url(#heat_slide_8__clip-path)">
                <path
                  id="heat_slide_8__eyes-4"
                  data-name="eyes"
                  d="M784.75 211.14a4.15 4.15 0 01-4.12 3.53 3.18 3.18 0 01-3-3.77 4.15 4.15 0 014.08-3.56 3.18 3.18 0 013.04 3.8z"
                />
              </g>
            </g>
          </g>
          <path
            id="heat_slide_8__n\xE6b"
            className="heat_slide_8__cls-9"
            d="M790.47 210.11l-5.79 1.38-.34-2.75 5.8-1.41a1.15 1.15 0 011.46 1v.23a1.52 1.52 0 01-1.13 1.55z"
          />
        </g>
        <g
          id="heat_slide_8__bird-4"
          data-name="bird"
          transform="translate(-1000, -150)"
        >
          <animateMotion
            dur="5s"
            repeatCount="indefinite"
            keyPoints="1;0"
            keyTimes="0;1"
            calcMode="linear"
            path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
          />
          <motion.path
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originY: 'bottom',
              originX: 'center',
            }}
            className="heat_slide_8__cls-5"
            d="M827 144.33a30.25 30.25 0 0116.4-16.14.63.63 0 01.55.07.61.61 0 01.28.49l1.17 32.36a.6.6 0 01-.19.46.58.58 0 01-.46.18l-19.35-1.2a.41.41 0 01-.16 0 .62.62 0 01-.41-.5l-.14-1.12a28.14 28.14 0 012.31-14.6z"
          />
          <path
            className="heat_slide_8__cls-6"
            d="M833.89 149.47l16.27-4a.6.6 0 01.49.09.63.63 0 01.26.43l1.41 11.42v.64a12.07 12.07 0 01-9.46 12.34l-25.86 5.4a.69.69 0 01-.32 0l-.15-.08a.6.6 0 01-.25-.41 21.51 21.51 0 01-.09-5.67 23.86 23.86 0 0117.7-20.16z"
          />
          <path
            className="heat_slide_8__cls-7"
            d="M833.78 161.5l17.85-4a.61.61 0 01.74.56 12.07 12.07 0 01-9.46 12.34l-16.66 3.48a.73.73 0 01-.33 0l-.15-.08a.6.6 0 01-.25-.42 10.85 10.85 0 018.26-11.88z"
          />
          <motion.path
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originY: 'bottom',
              originX: 'center',
            }}
            className="heat_slide_8__cls-6"
            d="M830.23 128.13a.57.57 0 01.53-.1.59.59 0 01.41.37l11.07 28.85a.62.62 0 010 .5.62.62 0 01-.38.31l-19.09 5.15a.63.63 0 01-.36 0 .59.59 0 01-.35-.31l-.46-1a25.67 25.67 0 01-2.15-14 29.84 29.84 0 0110.78-19.77z"
          />
          <g id="heat_slide_8__general_eye_shape-4" data-name="general eye shape">
            <g id="heat_slide_8__EYE-4" data-name="EYE">
              <path
                id="heat_slide_8__eyes-5"
                data-name="eyes"
                className="heat_slide_8__cls-1"
                d="M849.25 151a4.11 4.11 0 01-4.09 3.5 3.19 3.19 0 01-3-3.76 4.12 4.12 0 014.05-3.53 3.19 3.19 0 013.04 3.79z"
              />
              <g clipPath="url(#heat_slide_8__clip-path-2)">
                <path
                  id="heat_slide_8__eyes-6"
                  data-name="eyes"
                  d="M851.51 150a4.14 4.14 0 01-4.12 3.52 3.18 3.18 0 01-3-3.77 4.15 4.15 0 014.08-3.56 3.2 3.2 0 013.04 3.81z"
                />
              </g>
            </g>
          </g>
          <path
            id="heat_slide_8__n\xE6b-2"
            data-name="n\xE6b"
            className="heat_slide_8__cls-9"
            d="M857.23 149l-5.79 1.38-.34-2.74 5.8-1.41a1.13 1.13 0 011.45 1v.22a1.51 1.51 0 01-1.12 1.55z"
          />
        </g>
      </g>
    </svg>
  );
}

export default HeatAnimationSlide8;
